import React from "react";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import WellnessBenefitsStyle from "ps-assets/jss/WellnessBenefitsStyle.jsx";
import {  browserWindow } from "util/localStorageHelper";
import { Helmet } from "react-helmet";
import Button from 'components/CustomButtons/Button.jsx';
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Accordion from "components/Accordion/Accordion.jsx";
import { sevaApp } from "util/LayoutUtil";
const STRHOSPITALCOUNT = process.env.STRHOSPITALCOUNT;

const table1 = [
  ['Entry age (for adult)', '18 years to 65 years'],
  ['Policy period', '1 year/2 years/3 years'],
  ['Sum Insured in INR', '3 / 4 / 5 /10  lakhs'],
  ['Product type', 'Individual/Floater'],
  ['Discounts', 'On buying our health insurance policy online, avail 5% discount'],
]

const table2 = [
  ['Entry age (for adult)', '18 years to 65 years'],
  ['Dependent children', '91 days to 25 years'],
  ['Policy period', '1 year/ 2 years'],
  ['Sum Insured in  INR', <h5 key="t2si">Individual - 5 / 7 / 10 / 15 /  20 / 25 / 50 / 75 / 100 Lakhs<br/>Floater -- 5 / 10 / 15 /  20 / 25 / 50 / 75 / 100 Lakhs</h5>],
  ['Product type', 'Individual and Floater'],
  ['Discounts', 'On buying our medical insurance policy online, directly for the first time, avail 5% discount'],
]

const table3 = [
  ['Entry age ', '7 years to 70 years'],
  ['Policy period', '1 year/ 2 years / 3 years'],
  ['Sum Insured in INR', '5 / 7.5 / 10 /15 lakhs'],
  ['Cumulative Bonus', 'Up to 100% of basic sum insured'],
  ['Pre-acceptance Medical Screening', 'Not required'],
  ['Automatic Restoration', '100% of basic sum insured applicable for non-cardiac ailments'],
  ['Product type', 'Individual'],
  ['Discounts',<h5 key="Discounts">
<h5 key="Discounts2" style={{fontSize:'14px',fontWeight:'400'}}>10% discount on the renewal premium, by submitting specific medical reports and are normal.</h5></h5>],
]

const table4 = [
  ['Eligibility (for adult)', '18 – 65 years'],
  ['Dependent Children', '91 days - 25 years'],
  ['Policy period', '1 year/2 years/3 years'],
  ['Installment facility for premium payment', 'Quarterly/Half-yearly'],
  ['Sum Insured in INR', '5 / 7.5 / 10 / 15 /20 /25 /50 /75 / 100  Lakhs '],
  ['Product type', 'Individual and Floater'],
  ['Discounts', <h5 key="Discounts">
  <h5 key="Discounts1" style={{fontSize:'14px',fontWeight:'400'}}>2% - 10% - on Renewal Premium under Wellness Rewards Program</h5>
<h5 key="Discounts2" style={{fontSize:'14px',fontWeight:'400'}}>On buying our health insurance policy online, avail 5% discount</h5></h5>],
]

const table5 = [
  ['Entry Age', '60 years to 75 years'],
  ['Renewal', 'Lifelong'],
  ['Policy period','1 year/ 2 years / 3 years'],
  ['Sum Insured in INR', <h5 key="SumInsured">
  <h5 key="SumInsured1" style={{fontSize:'14px',fontWeight:'400'}}>Individual :- 1 / 2 / 3 /4 / 5 / 7.5 / 10 / 15 / 20 / 25 in Lakhs</h5>
<h5 key="SumInsured2" style={{fontSize:'14px',fontWeight:'400'}}>Floater :- 10 / 15 / 20 / 25 in lakhs</h5></h5>],
  ['Pre-insurance Medical Screening', 'Not Required'],
  ['Co-payment', 'For S.I. :- 1 / 2 / 3 / 4 / 5 / 7.5 / 10 / 15/ 20/ 25 Lakhs (All Claims – 30%)'],
  ['Discounts', <h5 key="Discounts">
  <h5 key="Discounts1" style={{fontSize:'14px',fontWeight:'400'}}>5 % on online Purchase & Renewals</h5>
<h5 key="Discounts2" style={{fontSize:'14px',fontWeight:'400'}}>10% of the premium will be allowed if the proposer produces the following documents </h5>
<h5>
  <ol type="a" style={{fontSize:'14px',fontWeight:'400'}}>
    <li>
    Stress Thallium Report 
    </li>
    <li>
    BP Report 
    </li>
    <li>
    Sugar (blood & urine)
    </li>
    <li>
    Blood urea & creatinine
    </li>
  </ol>
</h5>
</h5>],
]

const table6 = [
  ['Entry age (for adult)', '18 years to 65 years'],
  ['Dependent children', '16 days to 25 years'],
  ['Policy period', '1 year'],
  ['Sum Insured in  INR', '3 / 4 /5 /10 /15 /20 /25 lakhs'],
  ['Product type', 'Floater'],
  ['Discounts', 'On buying our medical insurance policy online, directly for the first time, avail 5% discount'],
]

const table7 = [
  ['Renewal', 'Lifelong'],
  ['Policy period', '1 year/ 2 years / 3 years'],
  ['Sum Insured in INR', '3 / 4 / 5 /10 /15 /20 /25 Lakhs'],
  ['Discounts',<h5 key="Discounts">
  <h5 key="Discounts1" style={{fontSize:'14px',fontWeight:'400'}}>On buying our health insurance policy online, avail 5% discount</h5>
<h5 key="Discounts2" style={{fontSize:'14px',fontWeight:'400'}}>25% under gold variant (if insured, submits a proof of major organ donor.)</h5>
<h5 key="Discounts3" style={{fontSize:'14px',fontWeight:'400'}}>5% family discount - On buying additional policy for a family member</h5></h5>],
]
const table8 = [
  ['Renewal', 'Lifelong'],
  ['Policy period', '1 year'],
  ['Sum Insured in INR', '0.5 lakh to 10 lakhs ( multiples of 50 thousand)'],
  ['Co-payment', '5% to all admissible claims'],
  ['Discounts', '20% discount for rural population'],
]
const table9 = [
  ['Renewal', 'Lifelong'],
  ['Policy period', '1 year'],
  ['Sum Insured in INR', '25,000 / 50,000 / 75, 000 / 1,00, 000'],
  ['Discounts', <h5 key="Discounts">
  <h5 key="Discounts1" style={{fontSize:'14px',fontWeight:'400'}}>25% discount of the premium after every block of two continuous claim free years.</h5>
<h5 key="Discounts2" style={{fontSize:'14px',fontWeight:'400'}}>On buying our medical insurance policy online, avail 5% discount</h5></h5>],
]

    const faq=[
        {
            title:'Why do I need health insurance?',
            description:<h5>Health insurance plans assist you in protecting yourself and your near and dear ones from any financial risks or uncertainties that arise due to a medical emergency. A smart health insurance plan from a reliable health insurance provider can help you protect your hard-earned savings and assets when medical emergencies are on the rise.</h5>,
            content:false
        },
        {
            title:'How to renew my health insurance plan after one year?',
            description:<h5>The customer has the choice to renew the policy in different ways. You can do one among the following to easily renew your policy after your policy period is over.
                <ul>
                <li>
                Visit the nearest branch, pay the premium and get it renewed.
                </li>
                <li>
                For online renewals, visit our website and let it take you through the complete renewal process hassle-free.
                </li>
                <li>
                Call the intermediary and make payment for the premium.
                </li>
                </ul>
                <h5>Enjoy the continuity benefits like PED cover, bonus, health check-up, etc, by renewing your policy on or before policy expiry or during the grace period.</h5>
                <h5>However, Star Health and Allied Insurance Co. Ltd. facilitates renewal notice well in advance for your benefit, even though it is not mandatory to inform.The customer is solely responsible for paying the premium towards the policy and enjoy the benefits without any break in the insurance.</h5>
            </h5>,
            content:true
        },
        {
            title:'What is the maximum number of claims allowed over a year?',
            description:'Any number of claims is admissible only to the extent of the sum insured and bonus (if any) under the policy.',
            content:false
        },
        {
            title:'What is a Family Floater Plan?',
            description:'When your entire family (self, spouse and dependent children) can be covered under one health insurance plan, it is called the Family Floater Health Insurance Plan. The plan will cover all the medical expenses, including those arising out of sudden illness, accidents, daycare procedures/treatments for your family members as per the policy conditions.',
            content:false
        },
        {
            title:'My wife and children reside in Mysore while I live in Bangalore. Can I cover all of us in one policy?',
            description:<h5>
                Yes, you can cover the entire family under a single health insurance plan, irrespective of where they reside. Your health insurance policy is valid across India.
            </h5>,
            content:true
        },
        {
            title:'Does health insurance cover diagnostic charges like X-Rays, MRI, or ultrasound?',
            description:<h5>
                All our health insurance plans cover diagnostic tests like X-ray, MRI, blood tests and more, as long as they are associated with the in-patient hospitalisation for more than 24 hours. If the diagnostic tests are done for  evaluation/investigation without positive existence of illness are not payable.
            </h5>,
            content:true
        },
        {
            title:'Does any health insurance plan also cover the treatment for an existing disease?',
            description:`Under any Health Insurance Plan, pre-existing diseases and their complications will be covered after a certain period which is known as time-bound
             exclusions. At Star Health we offer policies that cover pre-existing diseases after one year of continuous renewals. Coverage for pre-existing diseases and their complications is product/plan specific. We request you to visit our policy clause to know more about the waiting periods,
              exclusions that are exclusive for every policy. Please visit our website to know more.`,
            content:false
        },
        {
            title:'I intend to buy a Mediclaim Health Insurance Policy. Are there policy provisions that would provide me with lump sum benefits in case of major critical illness or are they limited to treatment?',
            description:'Yes, we have a policy specially designed based on the customers’ requirement where a lump sum payment can be given on the first diagnosis of listed critical illnesses. Star Health offers health insurance policies like Cancer Care for patients already diagnosed with Stage 1 and Stage 2 of Cancer. We request you to visit our website to know more about our policies,before you buy our policies.',
            content:false
        },
        {
            title:'I have an insurance cover provided by my employer for Rs. 4,00,000 covering my entire family. Can I still take another family health insurance plan from you?',
            description:`Yes, you can take a family health insurance plan from us subject to providing the details about your existing policy.
             The best option would be a top-up plan. For example, Star Super Surplus Policy provides
              up to 1 crore coverage with a
              defined limit of 3 lakhs which would be very economical and affordable.`,
            content:false
        },
        {
            title:'What is a Hospital under Mediclaim health Insurance policy?',
            description:<h5>
                <h5>
                Under medical insurance, a hospital refers to an institution that offers various medical treatments and performs medical procedures to take care of the patient. For a hospital to be qualified, it should have qualified medical practitioners available round the clock.
                </h5>
                <h5>
                A minimum number of beds of 15 in urban and 10 in rural,  works 24/7, should have all facilities like ward boys and nursing, ICU and OT, registered as a hospital with the local authority. These are only the minimum qualifications of a hospital.
                </h5>
            </h5>
                ,
                content:true
        },
        {
            title:'What do you mean by Network/Non-network hospitalisation?',
            description:<h5>
            <h5>
            A ’network hospital’ is a hospital/nursing home that has an agreement and empanelled with Star Health Insurance for providing a cashless facility across India. Non-network hospitals are those with whom we do not have any agreement or empanelment with Star Health. Any policyholder seeking treatment in these non-network hospitals will have to initially pay for the treatment and later claim through a reimbursement procedure. Visit our website to locate the nearest network hospital.
            </h5>
            <h5>
            Note: Cashless facility is provided ONLY at the network hospitals.
            </h5>
        </h5>,
            content:true
        },
        {
            title:'What is the claim process for non-network hospitals?',
            description:<h5>
            <h5>
            Insured gives prior intimation about the treatment and the insured pays the expenses himself to the hospital and then claims for a reimbursement of those expenses within 15 days from the date of discharge.
            </h5>
            <ul>
                <li>
                Documents to be submitted for Reimbursement claims.
                </li>
                <li>
                Duly completed claim form.
                </li>
                <li>
                Pre-admission investigations and treatment papers.
                </li>
                <li>
                Discharge summary from the hospital.
                </li>
                <li>
                Cash receipts from the hospitals and chemists.
                </li>
                <li>
                Cash receipts and reports for tests done.
                </li>
                <li>
                Receipts from doctors, surgeons and anesthetist.
                </li>
                <li>
                Certificate from the attending doctor regarding the diagnosis.
                </li>
                <li>
                Copy of PAN card, cancelled cheque or NEFT Details.
                </li>
            </ul>
        </h5>,
            content:true
        },
        {
            title:'How can I obtain a claim number or a claim intimation number?',
            description:<h5>
                <h5>
                To obtain a claim number, the insured can reach the insurance desk at the hospital and request an intimation to the insurer. Intimation to Star Health Insurance can be done by calling 044 6900 6900 / 044 4674 5800/ 044-28302200 or fax the pre-authorization form to Star Health Insurance at 044 6900 6900/044-28260056 or e- mail us at support@starhealth.in. The insured can procure the pre-authorization form the hospitals or can be downloaded <a href="/sites/default/files/CLAIMFORM.pdf" target="_blank" style={{color:'black'}}>here</a>.
                </h5>
                <h5>
                Once the form is received, Star Health Insurance’s internal team of experienced doctors will explore the request and respond with an authorization letter or in some cases, a regret letter.
                </h5>
            </h5>
                ,
                content:true
        },
        {
            title:'Can a request for claim be rejected/repudiated?',
            description:<h5>
                <h5>
                A request for authorization for claims may be rejected by Star Health Insurance’s doctors based on various reasons.
                </h5>
                <h5>
                Reasons why your claim could be rejected but are not limited to:
                </h5>
                <ul>
                    <li>
                    A claim for Time Bound / Permanent exclusions in a health insurance plan
                    </li>
                    <li>
                    If Material Facts, are Non-disclosed / Mis – Represented / Fraudulent claims
                    </li>
                    <li>
                    Claims from Excluded Provider (only Payable  during a Life threatening situation / accident up to the stage of stabilization )
                    </li>
                    <li>
                    If you fail to submit the documents required for the claim
                    </li>
                </ul>
            </h5>
                ,
                content:true
        },
        {
            title:'What is the procedure to avail cashless facility during in-hospitalisation?',
            description:<h5>
                <h5>
                Fasten your cashless facility in our network hospitals by following simple, six-step process.
                </h5>
                <ol type="1" >
                    <li>
                    Approach the insurance desk at a network hospital and request an intimation.
                    </li>
                    <li>
                    Intimation can be done by calling 044 6900 6900 / 044 4674 5800 e-mail us : support@starhealth.in.
                    </li>
                    <li>
                    Submit your Star Health Insurance ID card for identification, pre-admission investigations and doctor’s consultation papers(if any) to the hospital.
                    </li>
                    <li>
                    Network hospitals will verify your identity and submit a duly filled pre-authorization form to Star Health.
                    </li>
                    <li>
                    Our doctors verify all the submitted documents before processing the claim as per terms and conditions. An assigned field doctor may visit the patient at the hospital (only if a processing doctor feels to get more information and triggers the field visit).
                    </li>
                    <li>
                    After discharge, the hospital will send the claim documents to the company and the authorized amount will be settled directly to the hospital.
                    </li>
                </ol>
                <h5>
                Note: The difference between the approved amount and actual amount has to be borne 	by the insured.
                </h5>
            </h5>
                ,
                content:true
        },
        {
            title:'What is a Health Card?',
            description:'A health card is an identity issued by the health insurance company, which will have the policy number, insured details that is sufficient to take a claim number during hospitalisation for value-added services like cashless facilities and many more.',
            content:false
        },
        {
            title:'What do you mean by the cashless facility under health insurance plans?',
            description:<h5>
                <h5>
                During hospitalisation, normally insured has to pay the hospital bill/treatment expenses out of pocket. Under a cashless facility the insurance company pays the treatment expenses directly to the hospital as per the policy conditions and the remaining should be borne by the insured.
                </h5>
                <h5>
                Star Health Insurance offers a cashless facility to reduce the financial burden to the insured at the time of hospitalisation across India.
                </h5>
            </h5>,
            content:true
        },
        {
            title:'Is there a minimum period of hospitalisation needed for health insurance plans?',
            description:'Yes, all Health Insurance Plans need a minimum of 24 hours of hospitalisation. However, this is not applicable for daycare procedures/treatments.',
            content:false
        },
        {
            title:'Is there an Income Tax exemption for health insurance policies?',
            description:<h5>
                <h5>
                Yes, a premium paid under the Health Insurance Policy is exempted from Income Tax under section 80D of the Income Tax Act. The exemption is up to Rs. 25,000 for an individual covering their family and dependent children.
                </h5>
                <h5>
                In case the proposer intends to cover their parents below 60 years under medical insurance, they are eligible for a deduction of another Rs. 25,000 under section 80D. If the age of the parent to be covered is above 60 years, the deduction available is Rs. 50,000 under the same section. If two family members above the age of 60 are insured under one policy, then the deductible tax amount extends to Rs 1 lakh.
                </h5>                    
            </h5>,
            content:true
        },
        {
            title:'Is a medical checkup necessary before buying a Health policy?',
            description:'Under Star Health Insurance company pre-insurance medical screening is product/plan specific. Please refer to our policy conditions to know more about the requirements, before buying a Health policy.',
            content:false
        },
        {
            title:'I am a foreign citizen, can I take a health policy for my child who is continuing further studies in India?',
            description:<h5>
                Yes. However, insured can only make claims for treatments taken in hospitals in India within the policy period.
            </h5>,
            content:true
        },
        {
            title:'Does any Health Insurance plan cover expenses for outpatient treatment?',
            description:<h5>
                <h5>
                Certainly. Star Health offers many plans that provide coverage for outpatients treatments. We recommend Star Comprehensive Insurance Policy designed to give you extensive coverage right from in-patient hospitalisation, daycare procedures, outpatient consultations, treatments, etc. We also recommend Star Outpatient Care Insurance Policy for your specialised needs on OP care. Please visit our website to know more on our policies.
                </h5>
            </h5>,
            content:true
        },
        {
            title:'I have been diagnosed with diabetes Mellitus for the past 5 years. Can I take a Mediclaim Policy with you?',
            description:<h5>
                <h5>
                Yes. Any disease/illness diagnosed or taking treatment should be disclosed and will be considered as pre-existing disease which would help them during any claim in a later date.
                </h5>
                <h5>
                We have various options to address the customer needs.
                </h5>
                <h5>
                Option 1: Under a standard policy waiting period varies for a pre-existing diseases (diabetes mellitus/hypertension) is covered after certain period like 48/36/12 months based on the products/plan opted.
                </h5>
                <h5>
                Option 2: Innovative product designed only for diabetes called Diabetes Safe Insurance Policy which covers persons with a history of diabetes. The plan not only covers diabetic complications but also other than diabetic complications. To know more about the Diabetes Safe Insurance Policy, please visit <span><u><a href="/diabetes-safe-insurance-policy">Diabetes Safe Insurance Policy</a></u></span>.
                </h5>
            </h5>,
            content:true
        },
        {
            title:'Is there a health insurance plan to cover my mother who is more than 65 years old and has been diabetic for the past 10 years?',
            description:<h5><h5>Yes, provided less than 75 years. Senior Citizens Red Carpet Health Insurance Policy is specially designed to give cover for elderly people /s in the age group of 60 and 75 yearsalong with a life-long renewal option. A nominal co-payment of 30% is applicable for all claims under this policy. Above all, pre- existing diseases and their complications are covered after a period of about 12 months.</h5></h5>,
            content:false
        },
        {
            title:'Can I take a health insurance plan for my 3year old child?',
            description:'Yes. A health insurance plan can be given to a child of 3 years old provided the parent(s) is the proposer.',
            content:false
        },
        {
            title:'Can I avail this Health Insurance Plan if I am not an Indian national but live in India?',
            description:<h5>
                Yes, foreigners living in India can be covered under a Health Insurance Plan. However, the coverage would be restricted to only treatments performed within India.
            </h5>,
            content:true
        },
        {
            title:'How does Planned Hospitalisation work under Health Insurance Plans?',
            description:'For planned surgery, approach the hospital prior to the date of admission who will send the completed pre-authorization form. Planned Hospitalization can be intimated between 7 to 10 days in advance.',
            content:false
        },
        {
            title:'Can I get pre- and post-hospitalisation expenses cover?',
            description:'Health Insurance Plans allow for reimbursement of pre- and post-hospitalisation of medical expenses incurred towards the ailment/disease for specific days mentioned in the policy clause.',
            content:false
        },
        {
            title:'What are the benefits of buying Health Insurance plans online?',
            description:<h5>
                Today, in the digital world and the current pandemic situation meeting people would be of high risk. Hence, buying health insurance online is ideal. When you buy health insurance plans online, you deal with the insurance company directly. You can ask them your doubts about the policy and get it clarified which is authentic and updated. Enjoy the benefit of 5% premium discount on your  first purchase.  Another greatest advantage of buying medical insurance online is that the policy issuance is faster online, allowing you to save time with just a click . 
                </h5>,
            content:true
        }
    ]

    const importancefaq=[
        {
            title:'1. Lifestyle Changes',
            description:<h5><h5>The modern lifestyle and lack of physical activity can impact the course of life of an individual. Surely there are certain challenges that tag along with lifestyle changes. One such challenge is the increasing number of lifestyle-related diseases.</h5>
            <h5>Does Health Insurance cover lifestyle disease? Yes, most lifestyle diseases are covered by Health Insurance Policies. Diabetes, hypertension, cardiovascular diseases, obesity, cancer, etc., are some lifestyle diseases that are covered. To be financially prepared opting for Health Insurance will be a great option.</h5></h5>,
            content:false
        },
        {
            title:'2. Rising Medical Inflation',
            description:<h5>
                <h5>Medical inflation is a serious issue to deal with, as it is a concern of every individual. The cost of treatment, surgery and other fees are increasing at a great speed. Treating diseases can be hefty, also it is not advisable to solely depend on one’s savings and assets.</h5>
                <h5>Health Insurance is a safety net that provides necessary financial assistance at times of medical emergencies. All your medical expenses will get covered at an affordable premium.</h5>
            </h5>,
            content:true
        },
        {
            title:'3. Financial Assistance',
            description:<h5><h5>Make sure not to miss out Health Insurance while planning your finance. Insuring yourself and your family members will make it certain that you are financially backed up in times of need. Medical inflation is ever-rising, so a sudden medical emergency may drain your saving.</h5>
            <h5>At such times, Health Insurance will cover the incurred medical expenses, therefore reducing your worry about medical expenses. If Health Insurance takes care of your medical expenses, you can preserve your hard-earned saving and utilise them for other purposes.</h5></h5>,
            content:false
        },
        {
            title:'4. Cashless Treatment',
            description:<h5><h5>Health Insurance entitles the policyholder to get cashless treatment from the insurer’s Networked Hospitals and also ensures the quality of treatment. Yes, every Health Insurance Company has its own Network Hospitals to provide cashless treatment. Cashless treatment is available for planned and emergency hospitalisation but only in Network Hospitals.</h5>
            <h5>Star Health and Allied Insurance Company Ltd., has 13,000 plus Network Hospitals. This ensures that the policyholder can get cashless treatment near their residence.</h5></h5>,
            content:false
        },
        {
            title:'5. Covers Pre-existing Diseases',
            description:<h5><h5>Most Health Insurance Policies cover <u><a href="https://www.starhealth.in/blog/pre-existing-diseases-covered-in-health-insurance">Pre-existing Diseases.</a></u> Any medical conditions or ailments from which the policyholder is already suffering before opting for the Health Insurance Policy is referred as Pre-existing Disease.</h5>
            <h5>Treating such diseases will put you off track from managing your finance if you think that your saving is all enough to pay the medical expenses. Most Health Insurance Policies cover Pre-existing diseases after a certain period if they are disclosed properly at the time of purchasing the policy.</h5>
            <h5>Star Health’s Star Comprehensive Health Insurance Policy offers an optional cover, Buyback PED, to reduce the waiting period for Pre-existing Diseases. The Buyback PED can reduce the waiting period from 36 to 12 months.</h5>
            </h5>,
            content:true
        },
    ]

    const reasonfaq=[
        {
            title:'At your Comfort:',
            description:'Now you can buy your Health Insurance Plan in the comfort of your home. No more physical visit is required to enquire about the Health Insurance plans and Company as everything is available online.',
            content:false
        },
        {
            title:'Abundant Choice:',
            description:'You have numerous choices, as most Health Insurance Companies offer customers to buy Health Insurance online. Take your time to pick the right plan by analysing them in detail.',
            content:true
        },
        {
            title:'Liability:',
            description:'The terms and conditions of the Insurance are a bit hard to understand. Most Health Insurance Companies have listed the benefits of their policies on their website. This makes them trustworthy and easy to understand.',
            content:false
        },
        {
            title:'Easy Comparison:',
            description:'Comparing various Health Plans online is easy. The best way to buy the right Health Insurance Policy is by comparing them. Via online, you can compare various policies within the company as well as with other companies.',
            content:false
        },
        {
            title:'Get Quote:',
            description:'Now, you do not have to wait for a person to calculate the premium and give you the quotes. Most Insurance Companies provide instant “Get Quote” by collecting their basic details such as age, number of persons, etc. With less effort, you can gather various quotes, which allows you to compare and choose what suits your needs.',
            content:true
        },
        {
            title:'Transparency',
            description:'Online processes are transparent. After completing the required information, you will proceed further to buy your Health Insurance Policy. The premium is calculated based on your requirements. The online platform allows you to add or subtract the benefit, Sum Insured, your family members, etc. Based on your inputs, the premium is calculated and you can see the variation in the premium.',
            content:true
        },
        {
            title:'Premium Discount:',
            description:'One significant benefit of buying a Health Insurance Plan online is the premium discount. Yes, Most Health Insurance companies provide discounts on premiums for the first purchase of the policy online.',
            content:true
        },
        {
            title:'Convenient:',
            description:'Buying Health Insurance plans online is more convenient as everything you need is available at your fingertips. Now you can buy your policy anywhere and at any time, needless to wait for either an appointment or to visit the Company.',
            content:true
        },
    ]

        

const HealthInsurancePageSEO = ({ classes }) => {
    let AccordionArray = faq.map(category => {
        return {
          title: category.title,
          content: (
            <>
            {category.content ? category.description : <h5>{category.description}</h5>}
            </>
          )
        };
      });
      let AccordionArrayimportancefaq = importancefaq.map(category => {
        return {
          title: category.title,
          content: (
            <>
            {category.content ? category.description : <h5>{category.description}</h5>}
            </>
          )
        };
      });
      let AccordionArrayreasonfaq = reasonfaq.map(category => {
        return {
          title: category.title,
          content: (
            <>
            {category.content ? category.description : <h5>{category.description}</h5>}
            </>
          )
        };
      });
    return (
        <>
        {!sevaApp() &&<GridContainer className={classes.containerDisplay}>
            <GridItem
                md={11}
                sm={11}
                xs={11}
                className={classNames(classes.mrAuto, classes.mlAuto, classes.infoArea,classes.handleMarginRight)}
            >
             <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>What is Health Insurance?</h2>
            </GridItem>
            <h5>
            Good health refers to being in a condition of complete mental, physical and social well-being but not mere absence of illness.  It is possible to get health insurance cover when leading a healthy life. But getting a comprehensive health insurance cover may be denied for your near and dear ones on acquiring some illness. Certain medical emergencies are difficult to foresee or prevent.
                </h5>

                 <h5>
                <h5>
                However, we can account for them with our variety of health insurance plans based on customer’s needs.
                </h5> 
                    </h5>
                <h5>
                Most of the Health insurance plans in India cover medical expenses that arise due to illnesses, accidents and include most of medical or surgical treatments to restore good health. In short, health insurance plans help ease fears, facilitate quality care, offer timely intervention for those affected and allow them to focus more on recovery.
                </h5>

                <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>Benefits of Health Insurance Plans</h2>
            </GridItem>
            <h5>
            Star Health Insurance offers various health insurance plans to meet customer’s needs and provides hassle-free in-house claim settlement. On buying our health insurance plans, you can benefit from:
                </h5>
                <ul>
                <li>
                Cashless Facility
                </li>
                <li>
                Tax Exemptions
                </li>
                <li>
                Value-Added Services
                </li>
                <li>
                Enduring Trust
                </li>
                <li>
                Customer Centric
                </li>
            </ul>
            <h5>
            <b>Cashless Facility from Network Hospitals:</b> Speed is the essence when treating the ailing. The cashless facility supported by our health insurance plans across India is the need of the hour. Star Health and Allied Insurance Company Ltd., processes more than 89.9% of hospitalization related to mediclaim health insurance claims within 2 hours under the cashless facility and 88% of claims get settled within 7 days on submission of complete documents under reimbursement.
            </h5>
            <h5>
            <b>Tax Exemptions:</b> Under Section 80 D of the Income Tax Act 1961-2021, an income taxpayer can avail tax exemptions on premium paid towards medical health insurance policies. On buying our health insurance plans, the taxpayer can avail tax exemptions for premiums paid towards self, spouse, dependant children and parents.
            </h5>
            <h5>
            <b>Value-Added Services:</b> Star Health Insurance Company provides value-added benefits like e-Medical Opinion, Telemedicine Services, Wellness Programs and rewards (which can be converted to premium discounts on renewals) and many more as value-added services. Star Health Insurance Company justifies being the Health Insurance Specialist.
            </h5>
            <h5>
            <b>Enduring Trust:</b> Star Health Insurance, being the Health Insurance Specialist in India upholds the customer values and helps them at times of hospitalization or daycare procedures for medical emergencies.
            </h5>
            <h5>
            <b>Customer Centric:</b> Star Health continues to provide convenient digital platforms and communication channels that are accessible and reachable to customers. We aim to meet our customers at the right time, in the right way, with the right care in a way that brings value to our customer’s delight.
            </h5>
            <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>Why Do I need Health Insurance?</h2>
            </GridItem>
            <h5>The standard phrase Health is wealth is familiar to everyone, so it is the individual’s priority to secure it. No matter how advanced we live with our developing technology, it is hard to predict the uncertainties. One great example is the COVID-19 pandemic, which baffled the whole world.</h5>
            <h5>Reports and studies show a steady increase in lifestyle diseases and a high medical inflation rate. Health Insurance Policy becomes necessary for everyone to be well prepared for such uncertainties and to mitigate medical expenses. Health Insurance can ensure that your family is well-protected from the sudden financial burden. </h5>
            <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>Here are 5 reasons that exhibit the importance of Health Insurance.</h2>
            </GridItem>
            <GridItem
                style={{ paddingLeft:'0px'}}
                    className={`${classes.mrAuto} ${classes.textCenter}`}
                    xs={12}
                    sm={12}
                    md={12}
                >
                <Accordion active={0} collapses={AccordionArrayimportancefaq} />
            </GridItem>
            <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>Why Buy Health Insurance Online?</h2>
            </GridItem>
            <h5>You can buy Health Insurance online with just a few clicks, so buying Health Insurance is no more a complicated task. Buying Health Insurance plans online has wide benefits and here are some of them.</h5>
            <GridItem
                style={{ paddingLeft:'0px'}}
                    className={`${classes.mrAuto} ${classes.textCenter}`}
                    xs={12}
                    sm={12}
                    md={12}
                >
                <Accordion active={0} collapses={AccordionArrayreasonfaq} />
            </GridItem>

            <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>Best Mediclaim Health Insurance Policy in India</h2>
            </GridItem>
            <h3 className={classes.h3_customstyle}>Health Insurance Plans provide Comprehensive Coverage</h3>
            <h5>
            Star Health insurance policies or mediclaim plans will cover all 24-hour in-patient hospitalizations due to illness, accidents and daycare treatments/procedures. All related pre-hospitalization and post-hospitalization expenses are also payable up to the specified days mentioned in the policy clause. 
            </h5>
            <h3 className={classes.h3_customstyle}>Health Insurance Plans provide more Flexibility</h3>
            <h5>
            Star Health Insurance plans provide more flexible benefits when the policyholder buys a mediclaim policy and goes through certain hardships. For instance when the sum insured gets exhausted, additional coverage is provided without any extra premium.  This is where benefits like Automatic Restoration of basic sum insured, Super Restoration of basic sum insured and Road Traffic Accident (RTA) on the sum insured comes into place.
            </h5>
            <h5>
            Note: These flexible benefits are product/policy specific. Please refer to the policy clause to know more
            </h5>
            <h3 className={classes.h3_customstyle}>Health Insurance Plans Allow Additional Disease-Specific Coverage</h3>
            <h5>
            With Star Health, the insured can avail of disease-specific policies for critical illnesses, cancer and cardiac ailments along with in-patient hospitalization as a general mediclaim health insurance policy. We have a wide range of mediclaim health insurance policies to choose like Star Cardiac Care Insurance Policy-Platinum, Star Cancer Care Platinum Insurance Policy, Star Cancer Care Platinum Insurance Policy.
            </h5>
            <h3 className={classes.h3_customstyle}>Health Insurance Plans Cover non-Hospitalization Expenses</h3>
            <h5>
            The insured can avail of non-hospitalization expenses covered with most of our mediclaim health insurance policies. Those include expenses, Dental Treatments, annual health check-ups, out-patient care treatments, diagnostics, consultations, etc.
            </h5>

            <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>Our Best Health Insurance Plans</h2>
            </GridItem>
            <h5>
            Health issues increase with a sedentary lifestyle and advancement in age to a larger population. The current pandemic situation has underlined the need for health insurance more than ever before. All our policies provide cover for COVID-19, on purchase of a health insurance plan by a healthy individual/family, if diagnosed with COVID-19 and require hospitalization. Covid-19 treatments are  covered with some waiting period as mentioned in the policy clauses.
            </h5>
            <h5>
            Our varied, feature-rich health insurance plans are available for several health issues and provide peace of mind at times of financial crisis due to medical contingencies and uncertainties.
            </h5>
            <h5>
            Health insurance plans primarily are of different types and are classified under two categories based on their coverage – for an Individual and Family.
            </h5>

            <ul>
                <li>
                <b>Individual Health Insurance Plan</b>
                </li>
            </ul>
            <h5>
            Buying Individual Health Insurance plans cover an individual person for opted Sum insured, which can be used by the insured individual only.
            </h5>
            <ul>
                <li>
                <b>Family Floater Health Insurance Plan</b>
                </li>
            </ul>
            <h5>
            In the context of <u><a href="/health-insurance-plans-for-family" >Family Health Insurance</a></u>, a family indicates self, spouse and the dependent children and parents.
            </h5>
            <h5>
            The Family Floater Health Insurance plan covers an entire family with a single premium, and the sum insured floats among the insured family members.  At hospitals across India, the insured and family members can avail of quality-assured in-patient hospitalization, modern treatments, diagnosis, surgeries, etc along with a host of other features.
            </h5>
            <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>Why Purchase a Health Insurance Policy at a Young Age?</h2>
            </GridItem>
            <h5>We are often confused about the right age to buy Health Insurance. The right age to purchase Health Insurance is hard to define, however it is advisable to buy it when you are young.</h5>
            <h5>Following are some of the noteworthy advantages of buying Health Insurance at a <u><a href="https://www.starhealth.in/blog/how-the-younger-generation-benefits-from-health-insurance">young age.</a></u> </h5>
            <h5><b>Pre-medical Insurance Screening:</b>In most Health Insurance Companies pre-medical test is not mandatory to avail the policy if you opt for one at a young age. Thereby the chance of your buying proposal getting refused is low.</h5>
            <h5><b>Low Premium: </b>The policyholder’s age plays a crucial role in calculating the Health Insurance premium. Therefore, the older you grow, the higher your Health Insurance premium will be. You can avail comprehensive coverage at an affordable premium if you buy Health Insurance at a young age.</h5>
            <h5><b>Waiting Period: </b>Health Insurance Policies levy a  <u><a href="https://www.starhealth.in/blog/how-does-health-insurance-with-a-waiting-period" >waiting period</a></u> for Pre-existing and Specific Diseases. The policyholder has to wait till the completion of the waiting period to claim for such diseases. Buying Health Insurance at a young age thereby provides you the greatest advantage. At a young age, you can overcome the waiting period without any hassle, as the chances of getting diagnosed with such an illness are comparatively less.</h5>
            <h5><b>Co-payment: </b>Sharing the medical expenses between the insured and insurer is referred as co-payment. If the policyholder buys a policy above 60 years of age, then most Health Insurance Companies make co-payment mandatory. This is because elderly people are more prone to disease or illness. <u><a href="https://www.starhealth.in/blog/what-is-co-pay-in-health-insurance" >Co-payment</a></u> is not mandatory if you opt for a Health Insurance Policy at a young age.</h5>
            <h5><b>No-claim Bonus: </b>The policyholder is eligible for the <u><a href="https://www.starhealth.in/blog/what-is-no-claim-bonus-ncb-in-health-insurance" >no-claim bonus</a></u> if he/she does not claim during the policy period. This bonus entitles the policyholder to enhance the Sum Insured for every claim-free year. At a young age, the chance of rising a claim is relatively less. Therefore, the accumulated bonus through successive renewals will increase the Sum Insured which will be favourable at the needful times.</h5>
            <h5><b>Health Check-ups: </b>The expenses incurred for annual health check-ups are covered in most Health Insurance Policies. Annual health check-ups help to keep your health on track and can diagnose abnormalities at the right time.</h5>
            <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>Waiting Period in Health Insurance</h2>
            </GridItem>
            <h5>The waiting period refers to the time duration during which the policyholder is supposed to wait to claim the benefits under the Health Insurance Policy. The terms and conditions of the waiting period differ from insurer to insurer.</h5>
            <h5><b>Initial Waiting Period</b></h5>
            <h5>Health Insurance Policies have an initial waiting period of 30 days. During the initial waiting period, the policyholder cannot file any claims except for the claims related to accidents.</h5>
            <h5><b>Waiting Period for Pre-existing & Specific Diseases </b></h5>
            <h5>Coverage for Pre-existing and specific diseases does not start right after the purchase of the policy. The policyholder can avail the benefits of the coverage for such diseases after the completion of the waiting period.</h5>
            <h5><b>Maternity Waiting Period</b></h5>
            <h5>Some Health Insurance Policies offer maternity benefits with a waiting period. Take time to know the waiting period and choose a policy that has less waiting periods.</h5>
            <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>Key factors to consider before buying your Health Insurance Policy</h2>
            </GridItem>
            <h5>Choosing the best Health Insurance is quite a task. It is hard to pick the best plan while numerous firms are offering Health Insurance. The inclusion and exclusion differ from each policy. Every information that you gather will help you in the process of choosing the right Health Insurance Plan.</h5>
            <h5>If you are planning to buy Health Insurance, you should also pick the right Insurance Provider.</h5>
            <h5>Check out the plans offered by the Company. Every Insurance Company has its own Network Hospitals, so make sure to choose a company that offers a wide Network of Hospitals. Give equal importance to the claim settlement ratio, and go for the one that has a high claim settlement ratio.</h5>
            <h5>When it comes to choosing the best Health Insurance Policy, be mindful of the following:</h5>
            <h5><b>Inclusion and exclusion</b> are the key factors, so it is important to read them carefully before opting for the policy. This can avoid misconceptions during the claim process. Understanding your Health Insurance coverage gives you an upper hand while filing a claim.</h5>
            <h5><b>Waiting Period</b> in Health Insurance should be of major concern because, during the waiting period, you cannot raise a claim to avail the benefits. Thereby, it is essential to know the duration of waiting periods. It is advisable to choose a policy that has a lower waiting period.</h5>
            <h5><b>Network Hospitals</b> are the hospitals that work in agreement with Insurance Companies to provide cashless treatment. Go for the insurer who has a wide network of hospitals as you can choose the one that suits you best.</h5>
            <h5><b>Co-payment</b>refers to the sharing of medical bills between the insured and the insurer. Some policies make co-pay mandatory and for some it is optional. Being aware of your co-pay gives you more flexibility while planning your finance.</h5>
            <h5><b>Sub-limits</b> are a common factor in Health Insurance. The policy might have sub-limits for different expenses such as room rent, Domiciliary treatment, AYUSH treatment, Cataract treatment, etc. So, the claim amount for such expenses is covered up to the mentioned sub-limits and you have to pay the balance amount. </h5>
            <h5><b>A cumulative Bonus</b> is also known as no-claim bonus. If you do not raise a claim during the policy period, then your Sum Insured will be enhanced by certain percentage. Such enhancement is called a cumulative bonus. The accumulated bonus will come in handy during the needful times. </h5>
            <h5><b>Our Best Speciality Health Insurance Plans</b></h5>
            <h5><b>Policy for Diabetes</b></h5>
            <h5>The evolving lifestyle gives rise to serious diseases and disorders. One such complication is Diabetes. With the rise in medical inflation, having a <a href="/diabetes-safe-insurance-policy" >Diabetes Insurance plan</a> can save you from your financial breakdown. Our Diabetes Safe Insurance Policy is specially designed to cater for the needs of people diagnosed with both type 1 & type 2 Diabetes.</h5>
            <h5><b>Policy for Cancer</b></h5>
            <h5>Cancer is a serious threat and one of the leading causes of death across the world. A specific insurance policy for cancer offers cover for hospitalisation, treatment and diagnosis related to cancer. Our Star Cancer Care Platinum Insurance Policy is specially designed for people who are diagnosed with Cancer. In addition, it also provides coverage for non-cancer ailments.</h5>
            <h5><b>Policy for Cardiac Ailments</b></h5>
            <h5>Lifestyle changes also come with complications. With ever-increasing risks of cardiovascular illnesses and other associated complications, it becomes crucial that you secure yourself with the best Health Insurance plan. Our Star Cardiac Care Insurance Policy-Platinum provides wide coverage for cardiac illnesses. It doesn’t stop there. It gives coverage for non-cardiac ailments as well.</h5>
            <h5><b>Policy for Critical Illness</b></h5>
            <h5>In most cases, health problems occur unexpectedly and the rising medical inflation adds to the trouble. The treatment for critical illness is expensive and runs for a longer course of time. Do not worry about the treatment expenses because our Star Critical Illness Multipay Insurance Policy, insures you against 37 critical illnesses. The policy provides a lump sum on the diagnosis of critical illnesses under 4 groups.</h5>

            <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>List of our Best-Buy Health Insurance Policies</h2>
            </GridItem>
            <ol>
                <li>
                <b>Diabetes Safe Insurance Policy (UIN: SHAHLIP23081V082223)</b>
                <br />
                <h5>
                Diabetes is among one of the most common disorders with a huge number of 50.9 million people, driving India to the second position of the most affected country in the world, says Diabetes Foundation of India in a recent survey. A sedentary lifestyle, usage of tobacco, and unhealthy food habits are the factors that stand foremost leading to diabetes, according to WHO.
                </h5>
                <h5>
                Diabetes Safe Insurance Policy offers coverage for regular in-patient hospitalization due to illness/accidents and diabetes complications arising out of Type 1/Type 2 . This is a specialized plan designed for people already with a history of diabetes. The plan covers diabetic complications and all other illnesses that require 24 hours of hospitalization on both individual and family floater basis.
                </h5>
                <div>
        <Paper className={classes.root}>
                    <Table className={classes.table} fixedHeader={false} style={{tableLayout: "auto" }}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    rowSpan={1}
                                    className={classes.tableCellwidth + ` ` + classes.headCell}
                                    // style={{minWidth:100, maxWidth:100}}
                                >
                                    Features
                               </TableCell>
                            <TableCell
                                colSpan={3}
                                align="center"
                                className={classes.tableCellwidth + ` ` + classes.headCell}
                            >
                                Criteria
                          </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {table1.map((row) => (
                            <TableRow key={row[0]}>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                    {row[0]}
                                </TableCell>
                                <TableCell align="center" 
                                colSpan={3}
                                className={classes.tableCellLeftcustomWidth}>
                                    {row[1]}
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow key='waiting_periods'>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Waiting period Non- diabetic compilations
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                PED-48 months
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Specific Diseases- 24 months
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Initial waiting period – 30 days (except for accidents)
                                </TableCell>
                            </TableRow>
                    </TableBody>
                </Table>
            </Paper>
        </div>

                <h5>
                <Button
                    variant='contained'
                    color='primary'
                    className={`${classes.button}`}
                    onClick={()=>{
                        browserWindow.location.href="/diabetes-safe-insurance-policy"
                    }}>
                   Know more
                    </Button>
                </h5>
                </li>
                <li>
                <b>Super Surplus Insurance Policy & Star Super Surplus (Floater) Insurance Policy are Top-Up Health Insurance Plans (UIN: SHAHLIP22034V062122)c</b>
                <br />
                <h5>
                During the pandemic, even the best coverage is not sufficient. This Health Insurance Plan is a top-up plan that will cover medical costs, over and above a threshold limit. The plan is designed to cover exorbitant medical expenses with affordable premiums up to 1 crore sum insured. This health insurance plan is available both on an individual and floater basis that can cover your family too.
                </h5>
                <Paper className={classes.root}>
                    <Table className={classes.table} fixedHeader={false} style={{tableLayout: "auto" }}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    rowSpan={1}
                                    className={classes.tableCellwidth + ` ` + classes.headCell}
                                    // style={{minWidth:100, maxWidth:100}}
                                >
                                    Features
                               </TableCell>
                            <TableCell
                                colSpan={3}
                                align="center"
                                className={classes.tableCellwidth + ` ` + classes.headCell}
                            >
                                Criteria
                          </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {table2.map((row) => (
                            <TableRow key={row[0]}>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                    {row[0]}
                                </TableCell>
                                <TableCell align="center" 
                                colSpan={3}
                                className={classes.tableCellLeftcustomWidth}>
                                    {row[1]}
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow key='waiting_periods'>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Waiting period
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                PED-12 months
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Specific Diseases- 12 months
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Initial waiting period – 30 days (except for accidents)
                                </TableCell>
                            </TableRow>
                    </TableBody>
                </Table>
            </Paper>

                <h5>
                <Button
                    variant='contained'
                    color='primary'
                    className={`${classes.button}`}
                    onClick={()=>{
                        browserWindow.location.href="/super-surplus-health-insurance-policy"
                    }}>
                   Know more
                    </Button>
                </h5>
                </li>
                <li>
                <b>Star Cardiac Care Insurance Policy-Platinum (UIN: SHAHLIP22032V052122)</b>
                <br />
                <h5>
                Your heart needs all the extra care and timely protection. This is a unique health insurance plan that helps individuals from the age of 7 to 70 years, diagnosed with a cardiac ailment or undergone a cardiac surgical intervention.
                </h5>
                <Paper className={classes.root}>
                    <Table className={classes.table} fixedHeader={false} style={{tableLayout: "auto" }}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    rowSpan={1}
                                    className={classes.tableCellwidth + ` ` + classes.headCell}
                                    // style={{minWidth:100, maxWidth:100}}
                                >
                                    Features
                               </TableCell>
                            <TableCell
                                colSpan={3}
                                align="center"
                                className={classes.tableCellwidth + ` ` + classes.headCell}
                            >
                                Criteria
                          </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {table3.map((row) => (
                            <TableRow key={row[0]}>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                    {row[0]}
                                </TableCell>
                                <TableCell align="center" 
                                colSpan={3}
                                className={classes.tableCellLeftcustomWidth}>
                                    {row[1]}
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow key='waiting_periods'>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Waiting period 
                                Non-cardiac  Ailments
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                PED-48 months
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Specific Diseases- 24 months
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Initial waiting period – 30 days (except for accidents)
                                </TableCell>
                            </TableRow>
                            <TableRow key='waiting_periods'>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Waiting Period 
                                Cardiac Ailments
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                30days
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Heart transplantation
                                24 months
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                
                                </TableCell>
                            </TableRow>
                    </TableBody>
                </Table>
            </Paper>
                <h5>
                <Button
                    variant='contained'
                    color='primary'
                    className={`${classes.button}`}
                    onClick={()=>{
                        browserWindow.location.href="/cardiac-care-insurance-policy"
                    }}>
                   Know more
                    </Button>
                </h5>
                </li>
                <li>
                <b>Star Comprehensive Insurance Policy (UIN: SHAHLIP22028V072122)</b>
                <br />
                <h5>
                As the name itself says, this Health Insurance Plan  is an all-inclusive plan that can cover both individual or family with a lifetime renewability benefit.
                </h5>
                <h5>
                With individual and family floater coverage, this health insurance plan offers various sum insured options. Depending on their needs, while buying the policy, the individuals/families can choose the specified sum insured option with a minimum of 5 lakhs and a maximum of 1 crore.
                </h5>
                <Paper className={classes.root}>
                    <Table className={classes.table} fixedHeader={false} style={{tableLayout: "auto" }}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    rowSpan={1}
                                    className={classes.tableCellwidth + ` ` + classes.headCell}
                                    // style={{minWidth:100, maxWidth:100}}
                                >
                                    Features
                               </TableCell>
                            <TableCell
                                colSpan={4}
                                align="center"
                                className={classes.tableCellwidth + ` ` + classes.headCell}
                            >
                                Criteria
                          </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {table4.map((row) => (
                            <TableRow key={row[0]}>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                    {row[0]}
                                </TableCell>
                                <TableCell align="center" 
                                colSpan={4}
                                className={classes.tableCellLeftcustomWidth}>
                                    {row[1]}
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow key='waiting_periods'>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Waiting Periods
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                PED-36 months
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Option to buy-back PED. Waiting periods reduced to 36 months to 12 months 
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Specific Diseases- 24 months
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Initial waiting period – 30 days (except for accidents)
                                </TableCell>
                            </TableRow>
                    </TableBody>
                </Table>
            </Paper>
                <h5>
                <Button
                    variant='contained'
                    color='primary'
                    className={`${classes.button}`}
                    onClick={()=>{
                        browserWindow.location.href="/comprehensive-health-insurance-plan"
                    }}>
                   Know more
                    </Button>
                </h5>
                </li>
                <li>
                <b>Senior Citizens Red Carpet Health Insurance Policy (UIN: SHAHLIP22199V062122)</b>
                <br />
                <h5>
                This Health Insurance Plan is an ideal health insurance policy for senior citizens in the age group of 60 and 75 years. The policy comes with a lifelong renewal option, covering a range of daycare procedures/treatments, pre-existing diseases, modern treatments and significant medical interventions that may be required for the elderly. A nominal co-payment of 30% for all claims.
                </h5>
                <h5>
                Return your parent’s unconditional support and love they bestowed. Ensure parents receive the right protection and care during their old age by gifting them the Senior Citizen Red Carpet Policy and avail tax exemption under section 80D.
                </h5>
                <Paper className={classes.root}>
                    <Table className={classes.table} fixedHeader={false} style={{tableLayout: "auto" }}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    rowSpan={1}
                                    className={classes.tableCellwidth + ` ` + classes.headCell}
                                    // style={{minWidth:100, maxWidth:100}}
                                >
                                    Subject
                               </TableCell>
                            <TableCell
                                colSpan={3}
                                align="center"
                                className={classes.tableCellwidth + ` ` + classes.headCell}
                            >
                                Criteria
                          </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {table5.map((row) => (
                            <TableRow key={row[0]}>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                    {row[0]}
                                </TableCell>
                                <TableCell align="center" 
                                colSpan={3}
                                className={classes.tableCellLeftcustomWidth}>
                                    {row[1]}
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow key='waiting_periods'>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Waiting period
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                PED-12 months
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Specific Diseases- 24 months
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Initial waiting period – 30 days (except for accidents)
                                </TableCell>
                            </TableRow>
                    </TableBody>
                </Table>
            </Paper>

                <h5>
                <Button
                    variant='contained'
                    color='primary'
                    className={`${classes.button}`}
                    onClick={()=>{
                        browserWindow.location.href="/health-insurance-plan-for-senior-citizens"
                    }}>
                   Know more
                    </Button>
                </h5>
                </li>
                <li>
                <b>Family Health Optima Insurance Plan (UIN: SHAHLIP23164V072223)</b>
                <br />
                <h5>
                Providing family needs can be economically challenging. Hence, a super saver policy keeping the expenses to a minimum yet covering the whole family can be your option. This Floater Health Insurance Plan is appropriately priced so that you can cope with the most challenging health issues of every family member, even the youngest ones. On buying the policy, you can protect your newborn from the very start of the 16th day of birth from any medical complications with in-hospitalisation charges covered. This plan comes with an array of USPs like Automatic Restoration at 300% of sum  insured, repatriation of mortal remains, compassionate travel, emergency domestic medical evacuation, organ donor expenses, recharge benefit, sum insured for road traffic accidents, assisted reproduction treatment and more.
                </h5>
                <Paper className={classes.root}>
                    <Table className={classes.table} fixedHeader={false} style={{tableLayout: "auto" }}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    rowSpan={1}
                                    className={classes.tableCellwidth + ` ` + classes.headCell}
                                    // style={{minWidth:100, maxWidth:100}}
                                >
                                    Features
                               </TableCell>
                            <TableCell
                                colSpan={3}
                                align="center"
                                className={classes.tableCellwidth + ` ` + classes.headCell}
                            >
                                Criteria
                          </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {table6.map((row) => (
                            <TableRow key={row[0]}>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                    {row[0]}
                                </TableCell>
                                <TableCell align="center" 
                                colSpan={3}
                                className={classes.tableCellLeftcustomWidth}>
                                    {row[1]}
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow key='waiting_periods'>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Waiting period
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                PED-48 months
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Specific Diseases- 24 months
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Initial waiting period – 30 days (except for accidents)
                                </TableCell>
                            </TableRow>
                    </TableBody>
                </Table>
            </Paper>
                <h5>
                <Button
                    variant='contained'
                    color='primary'
                    className={`${classes.button}`}
                    onClick={()=>{
                        browserWindow.location.href="/family-health-optima"
                    }}>
                   Know more
                    </Button>
                </h5>
                </li>
                <li>
                <b>Medi Classic Insurance Policy (Individual) (UIN: SHAHLIP23037V072223)</b>
                <br />
                <h5>
                This  Health Insurance Plan provides a plethora of benefits for individuals. With this feature-packed health insurance plan, anyone from 16 days to 65 years can be covered with no capping on age for renewals after 65 years.
                </h5>
                <Paper className={classes.root}>
                    <Table className={classes.table} fixedHeader={false} style={{tableLayout: "auto" }}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    rowSpan={1}
                                    className={classes.tableCellwidth + ` ` + classes.headCell}
                                    // style={{minWidth:100, maxWidth:100}}
                                >
                                    Subject
                               </TableCell>
                            <TableCell
                                colSpan={3}
                                align="center"
                                className={classes.tableCellwidth + ` ` + classes.headCell}
                            >
                                Criteria - Gold
                          </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    <TableRow key='entry_age'>
                            <TableCell  
                            rowSpan={2}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            Entry Age
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                16 days to 65 years
                            </TableCell>
                        </TableRow>
                        <TableRow key='entry_age_dependent'>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                Individual (1A) A= Adult
                            </TableCell>
                        </TableRow>
                        {table7.map((row) => (
                            <TableRow key={row[0]}>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                    {row[0]}
                                </TableCell>
                                <TableCell align="center" 
                                colSpan={3}
                                className={classes.tableCellLeftcustomWidth}>
                                    {row[1]}
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow key='waiting_periods'>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Waiting period
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                PED-48 months
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Specific Diseases- 24 months
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Initial waiting period – 30 days (except for accidents)
                                </TableCell>
                            </TableRow>
                    </TableBody>
                </Table>
            </Paper>
                <h5>
                <Button
                    variant='contained'
                    color='primary'
                    className={`${classes.button}`}
                    onClick={()=>{
                        browserWindow.location.href="/individual-health-insurance-plan"
                    }}>
                   Know more
                    </Button>
                </h5>
                </li>
                <li>
                <b>Arogya Sanjeevani Policy, Star Health and Allied Insurance Co Ltd (UIN: SHAHLIP22027V032122)</b>
                <br />
                <h5>
                Arogya Sanjeevani means wellness forever, something we want for ourselves and everyone around us. This wholesome health insurance plan mandated by IRDAI allows higher penetration of health insurance including self, spouse, dependent children and parents up to 65 years of age, of course with a lifelong renewal option.
                </h5>
                <h5>
                If buying affordable health insurance covering the basics is on your wish list, then Arogya Sanjeevani Policy is a great choice for the rural population offering a 20%  discount.  Preserve the tight-knit bonds of families in sickness and health with coverage up to 10 lakhs for in-patient hospitalisation, daycare procedures, COVID-19 treatment, AYUSH treatment and more.
                </h5>
                <Paper className={classes.root}>
                    <Table className={classes.table} fixedHeader={false} style={{tableLayout: "auto" }}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    rowSpan={1}
                                    className={classes.tableCellwidth + ` ` + classes.headCell}
                                    // style={{minWidth:100, maxWidth:100}}
                                >
                                    Subject
                               </TableCell>
                            <TableCell
                                colSpan={3}
                                align="center"
                                className={classes.tableCellwidth + ` ` + classes.headCell}
                            >
                                Criteria
                          </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    <TableRow key='entry_age'>
                            <TableCell  
                            rowSpan={2}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            Entry Age
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                18 years to 65 years
                            </TableCell>
                        </TableRow>
                        <TableRow key='entry_age_dependent'>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                Dependent Children – 3 months to 25 years
                            </TableCell>
                        </TableRow>
                        {table8.map((row) => (
                            <TableRow key={row[0]}>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                    {row[0]}
                                </TableCell>
                                <TableCell align="center" 
                                colSpan={3}
                                className={classes.tableCellLeftcustomWidth}>
                                    {row[1]}
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow key='waiting_periods'>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Waiting period
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                PED-48 months
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Specific Diseases- 24/48 months
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Initial waiting period – 30 days (except for accidents)
                                </TableCell>
                            </TableRow>
                    </TableBody>
                </Table>
            </Paper>
                <h5>
                <Button
                    variant='contained'
                    color='primary'
                    className={`${classes.button}`}
                    onClick={()=>{
                        browserWindow.location.href="/aroghya-sanjeevani"
                    }}>
                   Know more
                    </Button>
                </h5>
                </li>
                <li>
                <b>Star Outpatient Care Insurance Policy (UIN: SHAHLIP22231V012122)</b>
                <br />
                <h5>
                It’s better to solve a problem in the beginning than it becomes much bigger. Consulting a doctor to resolve a problem, outpatient consultations and treatments are the most crucial in general maintenance of our health. Outpatient treatments cannot be avoided and will be a top priority to feel good. Imagine the money you could save on that consultation with your dentist for a root canal, or the times you have visited an eye clinic.
                </h5>
                <h5>
                This health Insurance Plan covers outpatient treatment expenses like consultant fees, pharmacy bills, payments for X-rays, investigations and diagnostics physiotherapy, the so-called out-of-pocket expenses that may not be covered in your standard health plan. This health policy is available in three variants – Silver, Gold and Platinum. It is an industry-specific health insurance plan exclusively covering OP consultations. Reap the benefits of premiums discounted up to 25% post 2 claim-free years.
                </h5>
                <Paper className={classes.root}>
                    <Table className={classes.table} fixedHeader={false} style={{tableLayout: "auto" }}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    rowSpan={1}
                                    className={classes.tableCellwidth + ` ` + classes.headCell}
                                    // style={{minWidth:100, maxWidth:100}}
                                >
                                    Subject
                               </TableCell>
                            <TableCell
                                colSpan={3}
                                align="center"
                                className={classes.tableCellwidth + ` ` + classes.headCell}
                            >
                                Criteria
                          </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    <TableRow key='entry_age'>
                            <TableCell  
                            rowSpan={2}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            Entry Age
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                18 years to 50 years
                            </TableCell>
                        </TableRow>
                        <TableRow key='entry_age_dependent'>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                Dependent Children – 31 days to 25 years
                            </TableCell>
                        </TableRow>
                        {table9.map((row) => (
                            <TableRow key={row[0]}>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                    {row[0]}
                                </TableCell>
                                <TableCell align="center" 
                                colSpan={4}
                                className={classes.tableCellLeftcustomWidth}>
                                    {row[1]}
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow key='waiting_periods'>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Waiting period
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                PED- 48/24/12 months (silver/Gold/Platinum)
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Initial waiting period – 30 days (except for accidents)
                                </TableCell>
                                
                            </TableRow>
                    </TableBody>
                </Table>
            </Paper>

                <h5>
                <Button
                    variant='contained'
                    color='primary'
                    className={`${classes.button}`}
                    onClick={()=>{
                        browserWindow.location.href="/star-outpatient-care-insurance-policy"
                    }}>
                   Know more
                    </Button>
                </h5>
                </li>
                <li>
                <b>Star Hospital Cash Insurance Policy (UIN: SHAHLIP20046V011920)</b>
                <br />
                <h5>
                When it comes to treating a loved one, even the little things matter. This Health Insurance  Plan is designed to take care of every little expense you incur during your hospitalisation.
                </h5>
                <h5>
                This Health Insurance Plan provides daily hospital cash when you are confined in the hospital. The Policy can be a supplement or add-on to all the medical plans offered by Star Health. You can avail of cash-in-hand benefits of up to Rs. 5000 per day for the number of days chosen by you.
                </h5>
                <Paper className={classes.root}>
                    <Table className={classes.table} fixedHeader={false} style={{tableLayout: "auto" }}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    rowSpan={1}
                                    className={classes.tableCellwidth + ` ` + classes.headCell}
                                    // style={{minWidth:100, maxWidth:100}}
                                >
                                    Subject
                               </TableCell>
                            <TableCell
                                colSpan={4}
                                align="center"
                                className={classes.tableCellwidth + ` ` + classes.headCell}
                            >
                                Criteria
                          </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    <TableRow key='entry_age'>
                            <TableCell  
                            rowSpan={2}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            Entry Age
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={4}
                            className={classes.tableCellLeftcustomWidth}>
                                18 years to 50 years
                            </TableCell>
                        </TableRow>
                        <TableRow key='entry_age_dependent'>
                            <TableCell align="center" 
                            colSpan={4}
                            className={classes.tableCellLeftcustomWidth}>
                                Dependent Children – 91 days to 25 years
                            </TableCell>
                        </TableRow>
                        <TableRow key='Renewal'>
                            <TableCell  
                            rowSpan={1}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            Renewal
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={4}
                            className={classes.tableCellLeftcustomWidth}>
                                Lifelong
                            </TableCell>
                        </TableRow>
                        <TableRow key='Policyperiod'>
                            <TableCell  
                            rowSpan={1}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            Policy period
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={4}
                            className={classes.tableCellLeftcustomWidth}>
                                1 year / 2 years / 3 years
                            </TableCell>
                        </TableRow>
                        <TableRow key='PlanOptions'>
                            <TableCell  
                            rowSpan={1}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            Plan Options
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={1}
                            rowSpan={2}
                            className={classes.tableCellLeftcustomWidth}>
                                Basic Amount (per day) options 
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={1}
                            className={classes.tableCellLeftcustomWidth}>
                                1000/- , 2000/-, 3000/-
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={1}
                            rowSpan={2}
                            className={classes.tableCellLeftcustomWidth}>
                                Enhanced Amount (per day) options
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={1}
                            className={classes.tableCellLeftcustomWidth}>
                                3000/-, 4000/- , 5000/-
                            </TableCell>
                        </TableRow>
                        <TableRow key='Policyperiod'>
                            <TableCell  
                            rowSpan={1}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            No. of days
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={1}
                            className={classes.tableCellLeftcustomWidth}>
                                30 / 60 /90/ 120/180
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={1}
                            className={classes.tableCellLeftcustomWidth}>
                                90 / 120 / 180
                            </TableCell>
                        </TableRow>
                        <TableRow key='Discounts'>
                            <TableCell  
                            rowSpan={1}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            Discounts
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={4}
                            className={classes.tableCellLeftcustomWidth}>
                              <ul>
                                <li>10% - for covering more than 2 family members under the same policy under Individual cover</li>
                                <li>On buying our health insurance policy online, avail 5% discount</li>
                              </ul>
                            </TableCell>
                        </TableRow>
                        

                        <TableRow key='waiting_periods'>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Waiting period
                                </TableCell>
                                <TableCell colSpan={2} align="center" className={classes.tableCellLeftcustomWidth}>
                                <h5>Basic Plan</h5>
                                <ul>
                                <li>PED – 36 months</li>
                                <li>
                                Specific diseases – 24 months</li>
                                <li>Initial illness – 30 days</li>
                                </ul>
                                </TableCell>
                                <TableCell colSpan={2} align="center" className={classes.tableCellLeftcustomWidth}>
                                <h5>Enhanced Plan</h5>
                                <ul>
                                  <li>PED – 24 months</li>
                                  <li>Specific Diseases – 24 Months</li>
                                  <li>Initial illness – 30 days</li>
                                </ul>
                                </TableCell>
                                
                            </TableRow>
                    </TableBody>
                </Table>
            </Paper>
            <ul>
              <li>The policy provides daily hospital cash during the insured’s confinement in the hospital in addition to any of the insured’s existing medical benefits.</li>
              <li>The Policy can be added to all the medical plans offered by Star Health.</li>
            </ul>
                <h5>
                <Button
                    variant='contained'
                    color='primary'
                    className={`${classes.button}`}
                    onClick={()=>{
                        browserWindow.location.href="/hospital-cash-insurance-policy"
                    }}>
                   Know more
                    </Button>
                </h5>
                </li>
                <li>
                <b>Young Star Insurance Policy (UIN: SHAHLIP22036V042122)</b>
                <br />
                <h5>
                India is one of the countries with the youngest population globally. India’s millennials are a valuable asset to the economic growth of the country. Hence, the youth of the nation should be filled with vibrancy and energy. Capitalize on the most vibrant years of your life with the help of this  Health Insurance Plan. This health plan is specially designed for young adults below 40 who envision a healthy life for themselves and their families.
                </h5>
                <h5>
                On buying the plan, you can avail wide range of benefits ranging from incentive-led wellness programs, the lowest waiting periods, coverage of pre- and post-hospitalisation expenses, no-claim bonus, annual check-ups, automatic restoration of the sum insured and additional basic sum insured for road traffic accidents.
                </h5>
                <Paper className={classes.root}>
                    <Table className={classes.table} fixedHeader={false} style={{tableLayout: "auto" }}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    rowSpan={1}
                                    className={classes.tableCellwidth + ` ` + classes.headCell}
                                    // style={{minWidth:100, maxWidth:100}}
                                >
                                    Subject
                               </TableCell>
                            <TableCell
                                colSpan={3}
                                align="center"
                                className={classes.tableCellwidth + ` ` + classes.headCell}
                            >
                                Criteria
                          </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    <TableRow key='entry_age'>
                            <TableCell  
                            rowSpan={2}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            Entry Age
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                18 years to 40 years
                            </TableCell>
                        </TableRow>
                        <TableRow key='entry_age_dependent'>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                Dependent Children – 3 months to 25 years
                            </TableCell>
                        </TableRow>
                        <TableRow key='Renewal'>
                            <TableCell  
                            rowSpan={1}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            Renewal
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={1}
                            className={classes.tableCellLeftcustomWidth}>
                                Lifelong
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={1}
                            className={classes.tableCellLeftcustomWidth}>
                                Plan options
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={1}
                            className={classes.tableCellLeftcustomWidth}>
                                <ul>
                                  <li>Gold</li>
                                  <li>Silver</li>
                                </ul>
                            </TableCell>
                        </TableRow>
                        <TableRow key='Policy_period'>
                            <TableCell  
                            rowSpan={1}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            Policy period
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                            1 year / 2 years / 3 years instalment Premium – quarterly / half yearly
                            </TableCell>
                        </TableRow>
                        <TableRow key='Sum_Insured'>
                            <TableCell  
                            rowSpan={1}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            Sum Insured in INR
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                3  / 4 / 5 / 10 /15 /20 /25 /50 /75 /100 Lakhs
                            </TableCell>
                        </TableRow>
                        <TableRow key='Discounts'>
                            <TableCell  
                            rowSpan={1}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            Discounts
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                <h5 style={{fontSize:'14px',fontWeight:'400'}}>2% - 10% - on Renewal Premium under Wellness Rewards Program</h5>
                                <h5 style={{fontSize:'14px',fontWeight:'400'}}>On buying our medical insurance policy online, directly for the first time, avail 5% discount</h5>
                                <h5 style={{fontSize:'14px',fontWeight:'400'}}>Early Entry discount - 10% on the premium for renewing after 40 yrs , by purchasing it before 36 yrs</h5>
                            </TableCell>
                        </TableRow>
                        <TableRow key='Renewal'>
                            <TableCell  
                            rowSpan={1}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            Waiting Periods
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={1}
                            className={classes.tableCellLeftcustomWidth}>
                                PED-12 months
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={1}
                            className={classes.tableCellLeftcustomWidth}>
                                Specific Diseases- 12 months
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={1}
                            className={classes.tableCellLeftcustomWidth}>
                                Initial waiting period – 30 days (except for accidents)
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Paper>
                <h5>
                <Button
                    variant='contained'
                    color='primary'
                    className={`${classes.button}`}
                    onClick={()=>{
                        browserWindow.location.href="/young-star-insurance"
                    }}>
                   Know more
                    </Button>
                </h5>
                </li>

                <li>
                <b>Star Micro Rural and Farmers Care (UIN: SHAHMIP22038V032122)</b>
                <br />
                <h5>
                Access to good health and hospitals must be available to all.  In the case of this Health Insurance Plan, it does not matter where you live or how much you earn. This health insurance plan is designed to safeguard the hands that feed us.
                </h5>
                <h5>
                Get an individual health insurance plan for a sum insured of Rs. 1 lakh and a family floater plan for Rs. 2 lakh, respectively. Get coverage on room rent, consultant fees, ICU charges, modern treatments and all daycare procedures.
                </h5>
                <Paper className={classes.root}>
                    <Table className={classes.table} fixedHeader={false} style={{tableLayout: "auto" }}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    rowSpan={1}
                                    className={classes.tableCellwidth + ` ` + classes.headCell}
                                    // style={{minWidth:100, maxWidth:100}}
                                >
                                    Subject
                               </TableCell>
                            <TableCell
                                colSpan={3}
                                align="center"
                                className={classes.tableCellwidth + ` ` + classes.headCell}
                            >
                                Criteria
                          </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    <TableRow key='entry_age'>
                            <TableCell  
                            rowSpan={2}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            Entry Age
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                18 years to 65 years
                            </TableCell>
                        </TableRow>
                        <TableRow key='entry_age_dependent'>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                Dependent Children – 12 months to 25 years
                            </TableCell>
                        </TableRow>
                        <TableRow key='Renewal'>
                            <TableCell  
                            rowSpan={1}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            Renewal
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                Lifelong
                            </TableCell>
                        </TableRow>
                        <TableRow key='Policy_period'>
                            <TableCell  
                            rowSpan={1}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            Policy period
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                1 year
                            </TableCell>
                        </TableRow>
                        <TableRow key='Sum_Insured'>
                            <TableCell  
                            rowSpan={2}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            Sum Insured
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                Individual - 1 lakh
                            </TableCell>
                        </TableRow>
                        <TableRow key='Sum_Insured'>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                Floater – 2 lakhs
                            </TableCell>
                        </TableRow>
                        <TableRow key='Co-payment'>
                            <TableCell  
                            rowSpan={1}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            Co-payment
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                20% to all admissible claims, if entry is 61 years & above
                            </TableCell>
                        </TableRow>
                        <TableRow key='waiting_periods'>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Waiting period
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                PED-6 months
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Specific Diseases - 6 months
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Initial waiting period – 30 days (except for accidents)
                                </TableCell>
                                
                            </TableRow>
                    </TableBody>
                </Table>
            </Paper>

                <h5>
                <Button
                    variant='contained'
                    color='primary'
                    className={`${classes.button}`}
                    onClick={()=>{
                        browserWindow.location.href="/micro-rural-and-farmers-care"
                    }}>
                   Know more
                    </Button>
                </h5>
                </li>
                <li>
                <b>Star Women Care Insurance Policy (UIN: SHAHLIP22217V012122)</b>
                <br/>
                <h5>Women play an integral part in shaping the society. In the midst of daily challenges such as new tasks to unfold her talent, dreams to focus on, goals to pursue, household work to complete, etc., she often forgets to care for herself. </h5>
                <h5>Do not let your health or related expenses bother you while embarking on your success journey. Our Star Women Care Insurance Policy is specially designed to favour the healthcare need of women. In addition, this policy can also be opted on a floater basis that provides cover for self, spouse and dependent children. This policy is crafted with major benefits such as star mother care, delivery expenses, assisted reproduction treatment, anti-natal care (pregnancy care), in-utero fetal surgery/repair, outpatient consultation, etc., that work in favour of the needs of women</h5>
                <Paper className={classes.root}>
                    <Table className={classes.table} fixedHeader={false} style={{tableLayout: "auto" }}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    rowSpan={1}
                                    className={classes.tableCellwidth + ` ` + classes.headCell}
                                    // style={{minWidth:100, maxWidth:100}}
                                >
                                    Subject
                               </TableCell>
                            <TableCell
                                colSpan={3}
                                align="center"
                                className={classes.tableCellwidth + ` ` + classes.headCell}
                            >
                                Criteria
                          </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    <TableRow key='entry_age'>
                            <TableCell  
                            rowSpan={1}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            Entry Age
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                18 years to 75 years
                            </TableCell>
                        </TableRow>
                        <TableRow key='entry_age_dependent'>
                            <TableCell align="center" 
                            className={classes.tableCellLeftcustomWidth}>
                                Dependent Children
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                91 days to 25 years
                            </TableCell>
                        </TableRow>
                        <TableRow key='Renewal'>
                            <TableCell  
                            rowSpan={1}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            Renewal
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                Lifelong
                            </TableCell>
                        </TableRow>
                        <TableRow key='Policy_period'>
                            <TableCell  
                            rowSpan={1}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            Policy period
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                1 year/ 2 years / 3 years
                            </TableCell>
                        </TableRow>
                        <TableRow key='Policy_period'>
                            <TableCell  
                            rowSpan={1}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            Product type
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                               Individual and Floater
                            </TableCell>
                        </TableRow>
                        <TableRow key='Sum_Insured'>
                            <TableCell  
                            rowSpan={1}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            Sum Insured in INR
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                5 / 10 / 15 / 20 / 25 / 50 / 100 in Lakhs
                            </TableCell>
                        </TableRow>
                        <TableRow key='Policy_period'>
                            <TableCell  
                            rowSpan={1}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            Pre- acceptance Medical Screening
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                Not Required. Pregnant women has to submit the scan reports taken at Star Health specified scan centres during their 12th and 20th week of their pregnancy period. The cost of such scan will be borne by the insured.
                            </TableCell>
                        </TableRow>
                        <TableRow key='Co-payment'>
                            <TableCell  
                            rowSpan={3}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            Discounts
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                5 % on online Purchase & Renewals
                            </TableCell>
                        </TableRow>
                        <TableRow key='Co-payment'>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                2% - 10% - on Renewal Premium under Star Wellness Program
                            </TableCell>
                        </TableRow>
                        <TableRow key='Co-payment'>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                Long Term Discount: 10% discount on 2nd year premium and 11.25% discount on 2nd and 3rd year premium.
                            </TableCell>
                        </TableRow>
                        <TableRow key='waiting_periods'>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Waiting period
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                PED-24 months
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Specific Diseases- 24 months
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Initial waiting period – 30 days (except for accidents)
                                </TableCell>
                                
                            </TableRow>
                    </TableBody>
                </Table>
                </Paper>
                <h5>
                <Button
                    variant='contained'
                    color='primary'
                    className={`${classes.button}`}
                    onClick={()=>{
                        browserWindow.location.href="/women-care-insurance"
                    }}>
                   Know more
                    </Button>
                </h5>
                </li>
                <li>
                <b>Star Health Premier Insurance Policy (UIN: SHAHLIP22226V012122)</b>
                <br/>
                <h5>Turning 50 doesn’t mean you are getting old, instead getting wiser than ever. If you still don’t have a Health Insurance policy, make a wise decision to buy one. Enjoy every stage of your life and do not let go of your hard-earned savings for medical expenses.</h5>
                <h5>In that concern, a Health Insurance Policy can provide cover for your medical expenses, thus making way to preserve your savings and enjoy your 50’s. Our Star Health Premier Insurance Policy designed for people aged 50 and above without any maximum age limit. The spouse and dependent children can also be covered under the same policy in Floater basis. This is a comprehensive policy having a Sum Insured option of up to Rs. 1 Crore with unique features such as home care treatment, domiciliary hospitalisation, coverage for non-medical items, air ambulance, hospice care, outpatient medical expenses, etc., at an affordable premium.</h5>
                <Paper className={classes.root}>
                    <Table className={classes.table} fixedHeader={false} style={{tableLayout: "auto" }}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    rowSpan={1}
                                    className={classes.tableCellwidth + ` ` + classes.headCell}
                                    // style={{minWidth:100, maxWidth:100}}
                                >
                                    Subject
                               </TableCell>
                            <TableCell
                                colSpan={3}
                                align="center"
                                className={classes.tableCellwidth + ` ` + classes.headCell}
                            >
                                Criteria
                          </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    <TableRow key='entry_age'>
                            <TableCell  
                            rowSpan={1}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            Entry Age
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                Min - 50 years for primary member & Max - Up to Any Age
                            </TableCell>
                        </TableRow>
                        <TableRow key='entry_age_dependent'>
                            <TableCell align="center" 
                            className={classes.tableCellLeftcustomWidth}>
                                Dependent Children
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                91 days to 25 years
                            </TableCell>
                        </TableRow>
                        <TableRow key='Renewal'>
                            <TableCell  
                            rowSpan={1}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            Renewal
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                Lifelong
                            </TableCell>
                        </TableRow>
                        <TableRow key='Policy_period'>
                            <TableCell  
                            rowSpan={1}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            Policy period
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                1 year/ 2 years / 3 years
                            </TableCell>
                        </TableRow>
                        <TableRow key='Policy_period'>
                            <TableCell  
                            rowSpan={1}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            Product type
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                               Individual and Floater
                            </TableCell>
                        </TableRow>
                        <TableRow key='Sum_Insured'>
                            <TableCell  
                            rowSpan={1}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            Sum Insured in INR
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                10 / 20 / 30 / 50 / 75 / 100 in Lakhs
                            </TableCell>
                        </TableRow>
                        <TableRow key='Policy_period'>
                            <TableCell  
                            rowSpan={1}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            Pre- acceptance Medical Screening
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                Not Required.
                            </TableCell>
                        </TableRow>
                        <TableRow key='Co-payment'>
                            <TableCell  
                            rowSpan={4}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            Discounts
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                5 % on online Purchase & Renewals
                            </TableCell>
                        </TableRow>
                        <TableRow key='Co-payment'>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                2% - 10% - on Renewal Premium under Star Wellness Program
                            </TableCell>
                        </TableRow>
                        <TableRow key='Co-payment'>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                Long Term Discount: If the policy term opted is 2 years, 10% discount on 2nd year premium and if the policy term opted is 3 years 11.25% discount on 2nd and 3rd year premium.
                            </TableCell>
                        </TableRow>
                        <TableRow key='Co-payment'>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                10% of the premium will be allowed if the proposer produces the following medical records and subject to the findings in the submitted reports
                                <ol type="a" >
                                    <li>Stress ECHO Report / TMT (done under guidance)</li>
                                    <li>BP Report (3 readings)</li>
                                    <li>Fasting Blood Sugar (FBS) and HbA1C</li>
                                    <li>Blood urea & creatinine</li>
                                    <li>Complete Urine Examination (CUE) </li>
                                </ol>
                            </TableCell>
                        </TableRow>
                        <TableRow key='waiting_periods'>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Waiting period
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                PED-24 months
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Specific Diseases- 24 months
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Initial waiting period – 30 days (except for accidents)
                                </TableCell>
                                
                            </TableRow>
                    </TableBody>
                </Table>
                </Paper>
                <h5>
                <Button
                    variant='contained'
                    color='primary'
                    className={`${classes.button}`}
                    onClick={()=>{
                        browserWindow.location.href="/health-premier-insurance"
                    }}>
                   Know more
                    </Button>
                </h5>
                </li>
                <li>
                <b>Star Health Assure Insurance Policy (UIN: SHAHLIP23017V012223)</b>
                <br/>
                <h5>Health and well-being are the greatest assets for everyone. Our Star Health Assure Insurance Policy can cover you and your loved ones under a single roof. This is a comprehensive insurance policy which can be availed either on an Individual or a Floater basis.</h5>
                <h5>The policy can cover up to 6 adults (self, spouse, parents & parents-in-law) and three dependent children. If the children covered under the Floater basis are above 17 years of age, then the family size can be 9 adults. This policy gives you several benefits such as mid-term inclusion, delivery expenses, home care treatment, treatment for chronic severe refractory asthma, assisted reproduction treatment, rehabilitation & pain management, compassionate travel and much more. You can avail various discounts in this policy such as long term discount, Star Wellness Program discount and premium discount on choosing deductibles (optional cover).</h5>
                <Paper className={classes.root}>
                    <Table className={classes.table} fixedHeader={false} style={{tableLayout: "auto" }}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    rowSpan={1}
                                    className={classes.tableCellwidth + ` ` + classes.headCell}
                                    // style={{minWidth:100, maxWidth:100}}
                                >
                                    Subject
                               </TableCell>
                            <TableCell
                                colSpan={3}
                                align="center"
                                className={classes.tableCellwidth + ` ` + classes.headCell}
                            >
                                Criteria
                          </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    <TableRow key='entry_age'>
                            <TableCell  
                            rowSpan={1}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            Entry Age
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                18 years to 75 years
                            </TableCell>
                        </TableRow>
                        <TableRow key='entry_age_dependent'>
                            <TableCell align="center" 
                            className={classes.tableCellLeftcustomWidth}>
                                Dependent Children
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                               16 days to 17 years
                            </TableCell>
                        </TableRow>
                        <TableRow key='Renewal'>
                            <TableCell  
                            rowSpan={1}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            Renewal
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                Lifelong
                            </TableCell>
                        </TableRow>
                        <TableRow key='Policy_period'>
                            <TableCell  
                            rowSpan={1}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            Policy period
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                1 year/ 2 years / 3 years
                            </TableCell>
                        </TableRow>
                        <TableRow key='Policy_period'>
                            <TableCell  
                            rowSpan={1}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            Product type
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                               Individual and Floater
                            </TableCell>
                        </TableRow>
                        <TableRow key='Sum_Insured'>
                            <TableCell  
                            rowSpan={1}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            Sum Insured in INR
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                5 / 10 / 15 / 20 / 25 / 50 / 75 / 100 / 200 in Lakhs
                            </TableCell>
                        </TableRow>
                        <TableRow key='Policy_period'>
                            <TableCell  
                            rowSpan={1}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            Pre- acceptance Medical Screening
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                Not Required.
                            </TableCell>
                        </TableRow>
                        <TableRow key='Co-payment'>
                            <TableCell  
                            rowSpan={3}
                                align="center" className={classes.tableCellLeftcustomWidth}>
                            Discounts
                            </TableCell>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                5 % on online Purchase & Renewals
                            </TableCell>
                        </TableRow>
                        <TableRow key='Co-payment'>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                4% - 20% - on Renewal Premium under Star Wellness Program
                            </TableCell>
                        </TableRow>
                        <TableRow key='Co-payment'>
                            <TableCell align="center" 
                            colSpan={3}
                            className={classes.tableCellLeftcustomWidth}>
                                Long Term Discount: If the policy term opted is 2 years, 10% discount on 2nd year premium and if the policy term opted is 3 years 10% on 2nd and 3rd year premium
                            </TableCell>
                        </TableRow>
                        <TableRow key='waiting_periods'>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Waiting period
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                PED-30 months (for 3 year term) & 36 months (for 1 & 2 year term)
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Specific Diseases- 24 months
                                </TableCell>
                                <TableCell align="center" className={classes.tableCellLeftcustomWidth}>
                                Initial waiting period – 30 days (except for accidents)
                                </TableCell>
                                
                            </TableRow>
                    </TableBody>
                </Table>
                </Paper>
                <h5>
                <Button
                    variant='contained'
                    color='primary'
                    className={`${classes.button}`}
                    onClick={()=>{
                        browserWindow.location.href="/star-assure-policy"
                    }}>
                   Know more
                    </Button>
                </h5>
                </li>
            </ol>
            
            <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>Why Star Health is a Best Health Insurance Company?</h2>
            </GridItem>
            <h5>
            Our motto is to serve the customers with the utmost care. We listen deeply to you, so we can provide health insurance plans that earn your trust.
            </h5>
            <h5>
            We are a Health Insurance Specialist, and here goes our list of most recent achievements on our customer-centric policies:
            </h5>
            <h5>
            <b>India’s Best Health Insurance Company For Retail Product –</b> Insurance Alerts
            </h5>
            <h5>
            <b>Most Innovative New Product of the Year 2020</b>
            </h5>
            <h5>
            Young Star Insurance Policy had been awarded the Most Innovative New Product of the Year at ASSOCHAM’s Insurance E-Summit and Awards 2020
            </h5>
            <h5>
            Best BFSI Brands 2019 by Economic Times
            </h5>
            <h5>
            Best Health Insurance Provider of the year – Business Today, Money Today Financial Awards 2018–2019
            </h5>
            <h5>
            Health Insurance Provider of the year Silver Award by Outlook Money Awards 2018
            </h5>
            
            <GridItem  style={{ paddingLeft:'0px'}}>
            <h3 className={classes.h3_customstyle}>Reasons to choose Star Health Insurance Company as your Insurance Provider</h3>
            </GridItem>
            <ul>
             <li>
                 <b>Customer – centric company</b> <br />
             </li>
            </ul>
            <h5>
            Products are customer-centric and designed based on customer needs – Star Diabetes Safe Insurance Policy, Star Cancer Care Platinum Insurance Policy, Star Cardiac Care Insurance Policy-Platinum, Star Cancer Care Platinum Insurance Policy, Young Star Insurance Policy , and many more, in the pipeline to serve you just the way our customers want.
            </h5>
            <h5>
            Star Health Insurance acts with a set of core values that include trust and integrity with a customer-first mindset. This enables us to go the extra mile for you with our health insurance plans. We consider ourselves privileged to have positively impacted the lives of those who choose to be a part of the Star family.
            </h5>

            <ul>
             <li>
                 <b>89.9% of  claims settled in less than 2 hours  under cashless at our network hospitals</b> <br />
             </li>
            </ul>
            <h5>
            Cashless facility is made available within 2 hours in network hospitals across India. Our in-house claim settlement process is a major factor, which has allowed us to achieve this massive success rate. We ensure a hassle-free claim process and settlement done by qualified in-house doctors.
            </h5>

            <ul>
             <li>
                 <b>PAN India presence</b> <br />
             </li>
            </ul>
            <h5>
            Ever-growing {STRHOSPITALCOUNT} network hospitals across India.
            </h5>

            <ul>
             <li>
                 <b>Claims settled by qualified doctors</b> <br />
             </li>
            </ul>
            <h5>
            A dedicated internal team of doctors to process your health insurance plan claims, and fast-track the proceedings. This team also weeds out those who use nefarious methods to gain funds and intend to make gain out of health insurance.
            </h5>

            <ul>
             <li>
                 <b>No Third-Party Administration (TPA)</b> <br />
             </li>
            </ul>
            <h5>
            A claim does justice only when it is settled within the timeframe. Often, many insurance companies avail services of TPA and incentivized for the claim processing. Star Health Insurance does not depend on the TPA but on our in-house claim team to help process claims faster and settle them in shortest time during the most needed hour.
            </h5>

            <ul>
             <li>
                 <b>A high-quality free telemedicine facility for all</b> <br />
             </li>
            </ul>
            <h5>
            Health is inclusive, and we believe in access to everybody, not only our customers. Hence, anyone can avail of our free telemedicine facilities. Talk to Star App is a specialised application designed to serve this purpose.
            </h5>

            <ul>
             <li>
                 <b>Wellness programs</b> <br />
             </li>
            </ul>
            <h5>
            Star Health Insurance supports the active maintenance of good health with unique wellness programs that encourage a healthy lifestyle.
            </h5>

            <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>How to claim?</h2>
            </GridItem>
            <h5>
            Star Health Claims Services is a hassle-free and customer-friendly process that ensures all settlements are processed promptly. As your health insurance specialist, we make cashless claims available across all our network hospitals in India. Courtesy, attention, speed and efficiency are the high standards we strive to upkeep. We value our customer’s feedback and work hard to meet and exceed your expectations.
            </h5>
            <h3 className={classes.h3_customstyle}>How to get claims faster?</h3>
            <ul>
             <li>
             The Star Health website contains a list of network hospitals, including Agreed network hospitals.
             </li>
             <li>
             Search from the network list from our website and locate the nearest network hospital to your residence.
             </li>
             <li>
             For planned surgery, approach the hospital prior to the date of admission who will send the completed pre-authorization form.
             </li>
             <li>
             In the pre-authorization form, you are required to furnish your contact number.
             </li>
             <li>
             If details are not complete, approval of the authorization request may be delayed.
             </li>
            </ul>


            <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>Cashless Facility Procedures:</h2>
            </GridItem>
            <h5>
            Approach the insurance desk at a network hospital. Intimation can be given either through contacting us at 044 6900 6900 / 044 4674 5800 or e-mail us at 
            {' '}<a href="mailto:support@starhealth.in">support@starhealth.in</a>  
            </h5>
            <ul>
            <li>
            Inform the Operator to obtain Claim No.
             </li>
             <li>
             Customer ID / Policy No.
             </li>
             <li>
             Cause of Hospitalization
             </li>
             <li>
             Hospital Name
             </li>
             <li>
             Insured/Patient Name
             </li>
            </ul>

            <h5>
            Planned Hospitalisation can be intimated between 7 to 10 days in advance and intimate for Emergency Hospitalization is within 24 hours of hospitalisation.
            </h5>
            <ul>
            <li>
            Register claim.
            </li>
             <li>
             Reach the insurance desk and submit documents at the network hospital.
             </li>
             <li>
             Documents sent to Star Claims Team.
             </li>
             <li>
             Documents verified by our Claim Processing Team.
             </li>
             <li>
             The decision to approve/query/denial of cashless/rejection  conveyed to the network hospital within 2 hours.
             </li>
             <li>
             If approved, the claim is settled as per the policy conditions.
             </li>
             <li>
             Payment will reach the network hospital.
             </li>
             <li>
             Pay the difference and get discharged.
             </li>
            </ul>

            <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>Reimbursement claim procedures:</h2>
            </GridItem>
            <h5>
            The insured gives prior intimation about the treatment to the insurer and the insured pays the hospital bills. The insured then claims for a reimbursement of those expenses within 15 days from the date of discharge.
            </h5>
            <h5>
            <b>Documents to be submitted for Reimbursement claims:</b>
            </h5>
            <ul>
            <li>
            Duly completed claim form
             </li>
             <li>
             Pre-admission investigations and treatment papers
             </li>
             <li>
             Discharge summary from the hospital
             </li>
             <li>
             Cash receipts from the hospital and chemists
             </li>
             <li>
             Cash receipts and reports for tests done
             </li>
             <li>
             Receipts from doctors, surgeons and anesthetist
             </li>
             <li>
             Certificate from the attending doctor regarding the diagnosis
             </li>
             <li>
             Copy of PAN card, cancelled cheque or NEFT details
             </li>
            </ul>
            <h5>
            You may also reach out to our 24/7 customer care to get your queries cleared.
            </h5>
            <GridItem  style={{ paddingLeft:'0px'}}>
            <h2 className={classes.h2hTitle}>FAQs</h2>
            </GridItem>

            <div>
            <div className={classes.section}>
            <div className={classes.container}>
                <GridContainer
                className={`${classes.container} ${classes.textCenter}`}
                >
                <GridItem
                    md={12}
                    style={{ paddingLeft:'0px'}}
                    className={`${classes.mrAuto} ${classes.mlAuto} ${classes.textCenter}`}
                ></GridItem>
                <GridItem
                style={{ paddingLeft:'0px'}}
                    className={`${classes.mrAuto} ${classes.textCenter}`}
                    xs={12}
                    sm={12}
                    md={12}
                >
                    <Accordion active={0} collapses={AccordionArray} />
                </GridItem>
                </GridContainer>
            </div>
            </div>
        </div>

        </GridItem>
      <Helmet>
            <title>
            Health Insurance Plans - India’s Best Medical Insurance Policy | Star Health
            </title>
            </Helmet>
            {process.env.ENABLE_HELMET == `true` && (
            <Helmet>
                <script type="application/ld+json">
                    {`{
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [
                {
                  "@type": "Question",
                  "name": "Why do I need health insurance?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Health insurance plans assist you in protecting yourself and your near and dear ones from any financial risks or uncertainties that arise due to a medical emergency. A smart health insurance plan from a reliable health insurance provider can help you protect your hard-earned savings and assets when medical emergencies are on the rise."
                  }
                },
                {
                  "@type": "Question",
                  "name": "How to renew my health insurance plan after one year?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "The customer has the choice to renew the policy in different ways. You can do one among the following to easily renew your policy after your policy period is over. \n Visit the nearest branch, pay the premium and get it renewed. \n For online renewals, visit our website and let it take you through the complete renewal process hassle-free. \n Call the intermediary and make payment for the premium. \nEnjoy the continuity benefits like PED cover, bonus, health check-up, etc, by renewing your policy on or before policy expiry or during the grace period. \nHowever, Star Health Insurance facilitates renewal notice well in advance for your benefit, even though it is not mandatory to inform.The customer is solely responsible for paying the premium towards the policy and enjoy the benefits without any break in the insurance."
                  }
                },
                {
                  "@type": "Question",
                  "name": "What is the maximum number of claims allowed over a year?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Any number of claims is admissible only to the extent of the sum insured and bonus (if any) under the policy."
                  }
                },
                {
                  "@type": "Question",
                  "name": "What is a Family Floater Plan?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "When your entire family (self, spouse and dependent children) can be covered under one health insurance plan, it is called the Family Floater Health Insurance Plan. The plan will cover all the medical expenses, including those arising out of sudden illness, accidents, daycare procedures/treatments for your family members as per the policy conditions."
                  }
                },
                {
                  "@type": "Question",
                  "name": "My wife and children reside in Mysore while I live in Bangalore. Can I cover all of us in one policy?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes, you can cover the entire family under a single health insurance plan, irrespective of where they reside. Your health insurance policy is valid across India."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Does health insurance cover diagnostic charges like X-Rays, MRI, or ultrasound?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "All our health insurance plans cover diagnostic tests like X-ray, MRI, blood tests and more, as long as they are associated with the in-patient hospitalisation for more than 24 hours. If the diagnostic tests are done for  evaluation/investigation without positive existence of illness are not payable."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Does any health insurance plan also cover the treatment for an existing disease?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Under any Health Insurance Plan, pre-existing diseases and their complications will be covered after a certain period which is known as time-bound exclusions. At  Star Health we offer policies that cover pre-existing diseases after one year of continuous renewals. Coverage for pre-existing diseases and their complications is product/plan specific. We request you to visit our policy clause to know more about the waiting periods, exclusions that are exclusive for every policy. Please visit our website to know more."
                  }
                },
                {
                  "@type": "Question",
                  "name": "I intend to buy a Mediclaim Health Insurance Policy. Are there policy provisions that would provide me with lump sum benefits in case of major critical illness or are they limited to treatment?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes, we have a policy specially designed based on the customers’ requirement where a lump sum payment can be given on the first diagnosis of listed critical illnesses. Star Health offers health insurance policies like Cancer Care for patients already diagnosed with Stage 1 and Stage 2 of Cancer. We request you to visit our website to know more about our policies."
                  }
                },
                {
                  "@type": "Question",
                  "name": "I have an insurance cover provided by my employer for Rs. 4,00,000 covering my entire family. Can I still take another family health insurance plan from you?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes, you can take a family health insurance plan from us subject to providing the details about your existing policy. The best option would be a top-up plan. For example, Star Super Surplus Policy  provides up to 1 crore coverage with a defined limit of 3 lakhs which would be very economical and affordable."
                  }
                },
                {
                  "@type": "Question",
                  "name": "What is a Hospital under Mediclaim health Insurance policy?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Under medical insurance, a hospital refers to an institution that offers various medical treatments and performs medical procedures to take care of the patient. For a hospital to be qualified, it should have qualified medical practitioners available round the clock.\nA minimum number of beds of 15 in urban and 10 in rural,  works 24/7, should have all facilities like ward boys and nursing, ICU and OT, registered as a hospital with the local authority. These are only the minimum qualifications of a hospital. "
                  }
                },
                {
                  "@type": "Question",
                  "name": "What do you mean by Network/Non-network hospitalisation?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "A ’network hospital’ is a hospital/nursing home that has an agreement and empanelled with Star Health Insurance for providing a cashless facility. Non-network hospitals are those with whom we do not have any agreement or empanelment with Star Health. Any policyholder seeking treatment in these non-network hospitals will have to initially pay for the treatment and later claim through a reimbursement procedure. Visit our website to locate the nearest network hospital. \nNote: Cashless facility is provided ONLY at the network hospitals."
                  }
                },
                {
                  "@type": "Question",
                  "name": "What is the claim process for non-network hospitals?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "12.Insured gives prior intimation about the treatment and the insured pays the expenses himself to the hospital and then claims for a reimbursement of those expenses within 15 days from the date of discharge.  \n Documents to be submitted for Reimbursement claims.\n Duly completed claim form. \n Pre-admission investigations and treatment papers.\n Discharge summary from the hospital.\n Cash receipts from the hospitals and chemists.\n Cash receipts and reports for tests done.\n Receipts from doctors, surgeons and anesthetist.\n Certificate from the attending doctor regarding the diagnosis.\n Copy of PAN card, cancelled cheque or NEFT Details."
                  }
                },
                {
                  "@type": "Question",
                  "name": "How can I obtain a claim number or a claim intimation number?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "13.You can inform the Network Hospital to intimate Star Health Insurance at 044 6900 6900 / 044 4674 5800/ 044-28302200 or fax the pre-authorization form to Star Health Insurance at 044 6900 6900 /044-28260056. The form is already available with the Hospital. It can also be downloaded here.\nOnce the form is sent, Star Health Insurance’s internal team of experienced doctors will explore the request and respond with an authorization letter or in some cases, a regret letter."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Can a request for claim be rejected/repudiated?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "14.A request for authorization for claims may be rejected by Star Health Insurance’s doctors based on various reasons.    \nReasons why your claim could be rejected but are not limited to: \n A claim for Time Bound / Permanent exclusions in a health insurance plan \n If Material Facts, are Non-disclosed / Mis – Represented / Fraudulent claims \n Claims from Excluded Provider (only Payable  during a Life threatening situation / accident up to the stage of stabilization )\n If you fail to submit the documents required for the claim"
                  }
                },
                {
                  "@type": "Question",
                  "name": "What is the procedure to avail cashless facility during in-hospitalisation?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "15.Fasten your cashless facility in our network hospitals by following simple, six-step process.\na.Approach the insurance desk at a network hospital and request an intimation. \nb.Intimation can be done by calling 044 6900 6900 / 044 4674 5800 e-mail us : support@starhealth.in.\nc.Submit your Star Health Insurance ID card for identification, pre-admission investigations and doctor’s consultation papers(if any) to the hospital.\nd.Network hospitals will verify your identity and submit a duly filled pre-authorization form to Star Health. \ne.Our doctors verify all the submitted documents before processing the claim as per terms and conditions. An assigned field doctor may visit the patient at the hospital (only if a processing doctor feels to get more information and triggers the field visit). \nf.After discharge, the hospital will send the claim documents to the company and the authorized amount will be settled directly to the hospital.\n\n            Note: The difference between the approved amount and actual amount has to be borne \tby the insured."
                  }
                },
                {
                  "@type": "Question",
                  "name": "16.What is a Health Card?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "A health card is an identity issued by the health insurance company, which will have the policy number, insured details that is sufficient to take a claim number during hospitalisation for value-added services like cashless facilities and many more."
                  }
                },
                {
                  "@type": "Question",
                  "name": "What do you mean by the cashless facility under health insurance plans?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "During hospitalisation, normally insured has to pay the hospital bill/treatment expenses out of pocket. Under a cashless facility the insurance company pays the treatment expenses directly to the hospital as per the policy conditions and the remaining should be borne by the insured. \n\nStar Health Insurance offers a cashless facility to reduce the financial burden to the insured at the time of hospitalisation across India."
                  }
                },
                {
                  "@type": "Question",
                  "name": " Is there a minimum period of hospitalisation needed for health insurance plans?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes, all Health Insurance Plans need a minimum of 24 hours of hospitalisation. However, this is not applicable for daycare procedures/treatments."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Is there an Income Tax exemption for health insurance policies?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes, a premium paid under the Health Insurance Policy is exempted from Income Tax under section 80D of the Income Tax Act. The exemption is up to Rs. 25,000 for an individual covering their family and dependent children. \n\nIn case the proposer intends to cover their parents below 60 years under medical insurance, they are eligible for a deduction of another Rs. 25,000 under section 80D. If the age of the parent to be covered is above 60 years, the deduction available is Rs. 50,000 under the same section. If two family members above the age of 60 are insured under one policy, then the deductible tax amount extends to Rs 1 lakh."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Is a medical checkup necessary before buying a Health policy?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Under Star Health Insurance company pre-insurance medical screening is product/plan specific. Please refer to our policy conditions to know more about the requirements."
                  }
                },
                {
                  "@type": "Question",
                  "name": "I am a foreign citizen, can I take a health policy for my child who is continuing further studies in India?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes. However, insured can only make claims for treatments taken in hospitals in India within the policy period."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Does any Health Insurance plan cover expenses for outpatient treatment?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Certainly. Star Health offers many plans that provide coverage for outpatients   \ntreatments. We recommend Star Comprehensive Insurance Policy designed to give you extensive coverage right from in-patient hospitalisation, daycare procedures, outpatient consultations, treatments, etc. We also recommend Star Outpatient Care Insurance Policy for your specialised needs on OP care. Please visit our website to know more on our policies."
                  }
                },
                {
                  "@type": "Question",
                  "name": "I have been diagnosed with diabetes Mellitus for the past 5 years. Can I take a Mediclaim Policy with you?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes. Any disease/illness diagnosed or taking treatment should be disclosed and will be considered as pre-existing disease which would help them during any claim in a later date.   \nWe have various options to address the customer needs.   \nOption 1: Under a standard policy waiting period varies for a pre-existing diseases (diabetes mellitus/hypertension) is covered after certain period like 48/36/12 months based on the products/plan opted.   \nOption 2: Innovative product designed only for diabetes called Diabetes Safe Insurance Plan which covers persons with a history of diabetes. The plan not only covers diabetic complications but also other than diabetic complications. To know more about the Diabetes Safe Insurance Plan, please visit (product link)."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Is there a health insurance plan to cover my mother who is more than 65 years old and has been diabetic for the past 10 years?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes, provided less than 75 years. Star Senior Citizen Red Carpet Health Insurance Plan is specially designed to give cover for elderly people /s in the age group of 60 and 75 yearsalong with a life-long renewal option. We recommend our customers to opt for a higher sum insured of 15 lakhs and above to avail of the lower co-payment of 30% applicable on all in-patient claims. Above all, pre- existing diseases and their complications are covered after a period of about 12 months."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Can I take a health insurance plan for my 3year old child?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes. A health insurance plan can be given to a child of 3 years old provided the parent(s) is the proposer."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Can I avail this Health Insurance Plan if I am not an Indian national but live in India?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes, foreigners living in India can be covered under a Health Insurance Plan. However, the coverage would be restricted to only treatments performed within India."
                  }
                },
                {
                  "@type": "Question",
                  "name": "How does Planned Hospitalisation work under Health Insurance Plans?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "For planned surgery, approach the hospital prior to the date of admission who will send the completed pre-authorization form. Planned Hospitalization can be intimated between 7 to 10 days in advance. "
                  }
                },
                {
                  "@type": "Question",
                  "name": "Can I get pre- and post-hospitalisation expenses cover?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Health Insurance Plans allow for reimbursement of pre- and post-hospitalisation of medical expenses incurred towards the ailment/disease for specific days mentioned in the policy clause."
                  }
                },
                {
                  "@type": "Question",
                  "name": "What are the benefits of buying Health Insurance plans online?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Today, in the digital world and the current pandemic situation meeting people would be of high risk. Hence, buying health insurance online is ideal. When you buy health insurance plans online, you deal with the insurance company directly. You can ask them your doubts about the policy and get it clarified which is authentic and updated. Enjoy the benefit of 5% premium discount on your  first purchase.  Another greatest advantage of buying medical insurance online is that the policy issuance is faster online, allowing you to save time with just a click."
                  }
                }
             ]
            }`}</script>
            <meta
              name="title"
              content="Health Insurance Plans - India's Best Medical Insurance Policy | Star Health "
            />
            <meta
              name="description"
              content="Health insurance plans cover your medical expenses for illness, accidents etc. Buy Star Health's Insurance policy & avail best benefits like cashless hospitalisation, fast claims settlements, etc."
            />
            </Helmet>
            )}
    </GridContainer>}</>
  );
};
HealthInsurancePageSEO.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  pageContext: PropTypes.object,
};
export default withStyles(WellnessBenefitsStyle)(HealthInsurancePageSEO);