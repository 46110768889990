import React from "react";
import Carousel from "react-slick";
import Img from "gatsby-image";
import VerticalCard from "ps-components/Card/VerticalCard.jsx";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import productListStyle from "ps-assets/jss/productListStyle.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Link, withPrefix } from "gatsby";
import Layout from "ps-components/Layout/Layout.jsx";
import { graphql } from "gatsby";
import HealthInsurancePageSEO from './seo/HealthInsurancePageSEO';
import CustomHidden from "ps-components/CustomHidden/CustomHidden";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";
import { Helmet } from "react-helmet";
import ReactSVG from "react-svg";
import Hidden from "@material-ui/core/Hidden";
import { browserWindow,browserDocument } from "util/localStorageHelper";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import MediaQuery from "react-responsive";
import PortabilityForm from "templates/PortabilityForm";
import { wizardLMSCall } from "util/lmsRequest";
import breadcrumbsjson from 'jsons/breadcrumbs'
import TravelInsurancePageSEO from './seo/TravelInsurancePageSEO';
import { sevaApp } from 'util/LayoutUtil.jsx';
const getPlanOrder = (category) => {
  switch (category) {
    case `accident`:
      return [`Insuranceplans_37`,`Insuranceplans_77`,`Insuranceplans_7`];
    default:
      return [];
  }
};

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: false,
  initialSlide: 0,
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: false,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 0
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

class ProductList extends React.Component {

  state ={
    recommendedPlans:[],
    hovered: false,
    mode: `buy`,
    form: {
      name: ``,
      mobile: ``,
      renew: ``,
      policy: ``,
      insuranceType: ``
    },
    links: {
      myself: `/comprehensive-health-insurance-plan`,
      family: `/comprehensive-health-insurance-plan`,
      parents: `/health-insurance-plan-for-senior-citizens`
    },
    adults: {
      myself: `1`,
      family: `1`,
      parents: `2`
    },
    children: {
      myself: `0`,
      family: `1`,
      parents: `0`
    },
    webPSupport: true,
    submitStatus: false,
    signupModal: false,
  };

  componentWillMount =() => {
    // browserWindow.addEventListener(`storage`,event => {
    //   if(event.key === `recommendedProducts`){
    //     this.setRecommendedPlans();
    //   }
    // });
    this.setRecommendedPlans();
  }

  componentDidMount=()=>{
    console.log(breadcrumbsjson[browserWindow.location.pathname], browserWindow.location.pathname)
    this.addLdScript(breadcrumbsjson[browserWindow.location.pathname])
  }
  // componentDidMount =()=> {
  //   this.addLdScript(breadcrumbsjson[browserWindow.location.href])
  // }
  componentWillUnmount =()=> {
    this.removeLdScript()
  }

  addLdScript=(script)=>{
    let ldjsonScript = browserDocument.createElement(`script`)
    ldjsonScript.type=`application/ld+json`
    ldjsonScript.id=`ld-json-script/bradcrumbs`
    if(!browserDocument.getElementById('ld-json-script') && script){
      ldjsonScript.innerHTML = `${JSON.stringify(script)}`;
      browserDocument.body.appendChild(ldjsonScript);
    }
  }

  removeLdScript=()=>{
    const ldjsonScript = browserDocument.getElementById(`ld-json-script/bradcrumbs`)
    if(ldjsonScript){
      ldjsonScript.remove()
    }
  }

  getPlans = (productIdArray,recommendedFlag) => {
    const {data,pageContext} = this.props;
    let planOrder = getPlanOrder(pageContext.category);
    return data.allStrapiInsuranceplans.edges
      .filter(plan => productIdArray.includes(plan.node.id) === recommendedFlag && plan.node.isWithdrawn !== true && !plan.node.isVisiblelist)
      .sort((a,b) => planOrder.indexOf(a.node.id) - planOrder.indexOf(b.node.id))  
  };

  getDefaultRecommendedPlans = () => {
    const { data } = this.props;
    return data.allStrapiInsuranceplans.edges
      .filter(plan => plan.node.isRecommended === true)
      .map(plan => plan.node.id);
  }

  setRecommendedPlans = () => {
    // const getWebSiteId = (id) => {
    //   switch(id.toLowerCase()){
    //     case `insuranceplans_72`: return `Insuranceplans_68`;
    //     case `insuranceplans_70` : return `Insuranceplans_69`;
    //     case `insuranceplans_73` : return `Insuranceplans_70`;
    //     default : return id;
    //   }
    // };
    // const { data } = this.props;
    // const allPlansIds = data.allStrapiInsuranceplans.edges.map(edge => edge.node.id);
    // let localRecommendedProducts = browserLocalStorage.getItem(`recommendedProducts`);
    // if(localRecommendedProducts && localRecommendedProducts != ``){
    //   localRecommendedProducts = localRecommendedProducts.split(`,`);
    //   localRecommendedProducts = localRecommendedProducts
    //     .map(plan => getWebSiteId(plan))
    //     .filter(
    //       plan => allPlansIds.includes(plan)
    //     );
    //   localRecommendedProducts = Array.from(
    //     new Set([
    //       ...localRecommendedProducts,
    //       ...this.getDefaultRecommendedPlans()
    //     ])
    //   ).slice(0,3);
    //   this.setState({recommendedPlans:localRecommendedProducts});
    // }
    // else{
      this.setState({recommendedPlans:this.getDefaultRecommendedPlans()});
    // }
  }

  updateSignUpModal = (x) => {
    this.setState(x);
  }

  handleClickOpen(modal) {
    var x = [];
    x[modal] = true;
    this.updateSignUpModal(x);
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.updateSignUpModal(x);
  }

  setMode(mode) {
    this.setState({
      mode: mode
    });
  }

  setInputValueToState = (event, value) => {
    if (event.target.id === `mobile`) {
      if (!isNaN(event.target.value) && event.target.value.length <= 10) {
        this.setState({
          ...this.state,
          form: {
            ...this.state.form,
            [value]: event.target.value
          }
        });
      }
    }
      else this.setState({
        ...this.state,
        form: {
          ...this.state.form,
          [value]: event.target.value
        }
      });
    // this.setState({
    //   [event.target.value]: event.target.value
    // });
    event.preventDefault();
  };

  buy = () => {
  };
  handleSimple = event => {
    this.setState({
      form: { ...this.state.form, insuranceType: event.target.value }
    });
  };

  handleMouseOver = id => {
    this.setState({
      [id]: true
    });
  };
  handleBuyNow = (event) => {

    let { name, mobile} = this.state.form;
    let urlInfo = browserWindow.location.href

    const error = () => {
      if(!this.state.form.mobile || this.state.form.mobile.length != 10 && this.state.form.mobile.length != 0){
        return true
      }
      else return false
    }
    
    let errorCheck = error()
    if(errorCheck){
      event.preventDefault()
    }
    else wizardLMSCall(
      name,
      mobile,
      null,
      null,
      null,
      urlInfo,
    );

    this.setState({
      submitStatus: true
    });
  };
  
  handleMouseLeave = id => {
    this.setState({
      [id]: false
    });
  };

  displayEmiRibbon = (productId) => {
    switch(productId){
      case 26:
      case 3:
      case 6:
      case 25:
      case 14:
      case 11:
      case 4:
      case 2:
      case 7:
      case 8:
      case 37:
      case 33:
      case 34:
      case 40:
      case 41:
      case 42:
      case 43:
        return true;
      default: return false;
    }
  }
  render(){
    const { classes, data, location, pageContext } = this.props;
    let recommendedPlans = [],
      allPlans = [];

    recommendedPlans = this.getPlans(this.state.recommendedPlans,true).sort((a,b) => a.node.sequence - b.node.sequence);
    allPlans = this.getPlans(this.state.recommendedPlans,false);
      
    let url = process.env.BASE_URL;
    let urlold = process.env.BASE_URL_OLD;
    if (data.allStrapiInsuranceplans.edges[0].node.category == `accident`)
      data.allStrapiInsuranceplans.edges = data.allStrapiInsuranceplans.edges.reverse();

    const categoryData = data.strapiInsuranceplancategories;

    const nonCategories = data.allStrapiInsuranceplancategories.edges

    var colorCode = data.strapiInsuranceplancategories.colorCode;

    const getPlansComponent = plans => plans.map(document => {
      let image = document.node.thumbnailImages && (
        <a rel="noopener" href="#pablito" onClick={e => e.preventDefault()}>
          <img
            src={document.node.thumbnailImages.publicURL}
            width="60"
            height="60"
            style={{ boxShadow: `none` }}
          />
        </a>
      );
      let onHover = null,
        action = null,
        shareable = null,
        shareContent = <></>;
      if (!document.node.isWithdrawn) {
        onHover = [`action`, `shareable`];
        if (document.node.redirectUrl) {
          action = (
            <a
              rel="noopener noreferrer"
              target={`_blank`}
              // href={document.node.redirectUrl}
              href={ sevaApp() ? document.node.redirectUrl+'?sevaapp=true': document.node.redirectUrl }
              style={{ textDecoration: `none` }}
            >
              <Button className={`policyDetailPageBuyNowGA`}round style={{ background: colorCode }}>
                <span className={`policyDetailPageBuyNowGA`}>Buy Now</span>
              </Button>
            </a>
          );
        } else {
          action = (
            <Link
              // to={`/${document.node.urlPath}`}
              to={ sevaApp() ? `/${document.node.urlPath}?sevaapp=true`:`/${document.node.urlPath}`}
              style={{ textDecoration: `none` }}
            >
              <Button className={`policyDetailPageBuyNowGA`} round style={{ background: colorCode }}>
              <span className={`policyDetailPageBuyNowGA`}>Buy Now</span>
              </Button>
            </Link>
          );
        }
        shareable = true;
        shareContent = (
        <>
          <Button justIcon simple color="white">
            <FacebookShareButton
              title={document.node.name}
              url={`${location.origin}/${document.node.urlPath}`}
            >
              <ReactSVG
                src={withPrefix(`/web_facebook.svg`)}
                className={classes.socialIcon}
              />
            </FacebookShareButton>
          </Button>

          <Button justIcon simple color="white">
            <TwitterShareButton
              title={document.node.name}
              url={`${location.origin}/${document.node.urlPath}`}
            >
              <ReactSVG
                src={withPrefix(`/web_twitter.svg`)}
                className={classes.socialIcon}
              />
            </TwitterShareButton>
          </Button>
          <Button justIcon simple color="white">
            <WhatsappShareButton
              title={document.node.name}
              url={`${location.origin}/${document.node.urlPath}`}
            >
              <ReactSVG
                src={withPrefix(`/web_whatsapp.svg`)}
                className={classes.socialIcon}
              />
            </WhatsappShareButton>
          </Button>
        </>
        );
      }
      return (
        <GridItem key={document.node.id} md={4} lg={4} sm={12}>
          <VerticalCard
            title={document.node.name}
            image={image}
            action={action}
            description={document.node.summary}
            shareable={shareable}
            shareContent={shareContent}
            alignItems="center"
            onHover={onHover}
            noMarginHeader
            noShadow
            style={{
              marginTop: `10px`
            }}
            displayComponent={
              this.displayEmiRibbon(document.node.productId) &&
              (<img
                src={require(`assets/img/emi-plan-transparent.png`)}
                className={classes.emiLabel}
              />)
            }
          />
        </GridItem>
      );
    });
  
    const allPlansArr = getPlansComponent(
      allPlans
        .filter(
          plan => !plan.node.isSpecialityProduct
        )
    );
  

    const bannerContent = (item, matches, classes) => (
      <div
      className={`${classes.container}`}
      style={{
        alignSelf: `center`,
        marginTop: 0 ,
      }}
    >
      <>
         {!sevaApp() && <GridContainer
            style={{
              justifyContent: `left`,
              marginTop: matches ? `0px` : `25px`
            }}
          >
            <h1 className={classNames(
                classes.headerStyle
              )}>{categoryData.name}</h1>
            <br/>
            <p className={classNames(
                classes.parastyle
              )}
            >{categoryData.summary}</p>
            <br/>
            <br/>
            <GridItem
              xs={12}
              sm={3}
              md={3}
              lg={2}
              className={classNames(
                // classes.mlAuto,
                // classes.mrAuto,
                classes.textCenter,
                classes.buttonPadding,
                classes.buynow
              )}
            >
              <Button
                block
                color={
                  this.state.mode === `buy` ||
                  // (item.sequence !== 2 && 
                    this.state.mode === ``
                    // )
                    ? `primary`
                    : `transparent`
                }
                className={`${classes.button} ${
                  this.state.mode !== `buy` ||
                  // (item.sequence === 2 && 
                    this.state.mode === ``
                    // )
                    ? matches
                      ? classes.primaryBorder
                      : classes.allBorder
                    : ``
                }`}
                onClick={() => this.setMode(`buy`)}
              >
                I want to Buy
              </Button>
            </GridItem>
            <GridItem
              xs={12}
              sm={3}
              md={3}
              lg={2}
              className={classNames(
                // classes.mlAuto,
                // classes.mrAuto,
                classes.textCenter,
                classes.buttonPadding,
                classes.portstyle
              )}
            >
              <Button
                value="i want to port"
                aria-label="i want to port"
                block
                color={
                  this.state.mode === `port` ||
                  // (item.sequence === 2 && 
                    this.state.mode === ``
                    // )
                    ? `primary`
                    : `transparent`
                }
                className={`${classes.button} ${classes.buttonMargin} ${
                  this.state.mode === `port` ||
                  // (item.sequence === 2 && 
                    this.state.mode === ``
                    // )
                    ? ``
                    : matches
                      ? classes.primaryBorder
                      : classes.allBorder
                }`}
                onClick={() => window.location.href = '/portability-plans'}
              >
                I want to Port
              </Button>
            </GridItem>
          </GridContainer>}
        {!sevaApp() &&<GridContainer
            style={{
              justifyContent: `flex-start`
            }}
          >
            <GridItem
              xs={12}
              sm={12}
              md={6}
              lg={6}
              className={classNames(
                // classes.mlAuto,
                // classes.mrAuto,
                classes.textCenter,
                classes.cardmargin
              )}
            >
              {this.state.mode !== `port` &&
                <Card
                  raised
                  className={`${classes.card} ${classes.cardStyle}`}
                  style={{
                    marginTop: `50px`
                  }}
                >
                  {(this.state.mode === `buy` ||
                    // (item.sequence !== 2 && 
                      this.state.mode === ``
                      // )
                      ) 
                      && (
                    <CardBody
                      formHorizontal
                      style={{
                        paddingTop: `0px`,
                        paddingBottom: `0px`
                      }}
                    >
                      <form aria-label="" onSubmit={this.buy}>
                        <GridContainer
                          style={{
                            alignItems: `center`,
                          }}
                        >
                          <GridItem xs={12} sm={3} md={3}>
                            <CustomInput
                              labelText="Your Name"
                              id="name"
                              role="presentation"
                              formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                              }}
                              inputProps={{
                                "aria-label": `customName`,
                                name: `customName`
                              }}
                              value={this.state.form.name}
                              onChange={e => this.setInputValueToState(e, `name`)}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={3} md={3}>
                            <CustomInput
                              labelText="Your Number"
                              id="mobile"
                              role="presentation"
                              formControlProps={{
                                fullWidth: true,
                                className: classes.formControl
                              }}
                              inputProps={{
                                required: true,
                                pattern: `[0-9]{10}`,
                                maxLength: `10`,
                                inputMode: `tel`,
                              }}
                              error={
                                (this.state.submitStatus && !this.state.form.mobile ) ||
                                (this.state.form.mobile.length != 10 && this.state.form.mobile.length != 0)
                              }
                              value={this.state.form.mobile}
                              onChange={e => this.setInputValueToState(e, `mobile`)}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={5} md={3}>
                            <FormControl
                              fullWidth
                              className={`${classes.selectFormControl} ${classes.formMargin}`}
                              error={
                                this.state.submitStatus &&
                                this.state.form.insuranceType === ``
                              }
                            >
                              <InputLabel
                                htmlFor="insurancetype"
                                className={classes.selectLabel}
                                style={{width: 110}}
                              >
                                I want to Insure
                              </InputLabel>
                              <Select
                                MenuProps={{
                                  className: classes.selectMenu
                                }}
                                classes={{
                                  select: classes.select
                                }}
                                value={this.state.form.insuranceType || ``}
                                role="none"
                                onChange={this.handleSimple}
                                style={{textAlign: `left`}}
                                inputProps={{
                                  name: `insurancetype`,
                                  id: `insurancetype`
                                }}
                                className={classNames(
                                  classes.insurestyle
                                )}
                              >
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value="myself"
                                >
                                  Myself
                                </MenuItem>
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value="family"
                                >
                                  My Family
                                </MenuItem>
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value="parents"
                                >
                                  My Parents
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </GridItem>
                          <GridItem
                            xs={12}
                            sm={4}
                            md={3}
                            style={{
                              alignSelf: `center`
                            }}
                          >
                            <Link
                              to={
                                this.state.links[this.state.form.insuranceType]
                                  ? this.state.links[
                                    this.state.form.insuranceType
                                  ] +
                                    `?name=${this.state.form.name}&mobile=${this.state.form.mobile}&noOfAdults=${
                                      this.state.adults[
                                        this.state.form.insuranceType
                                      ]
                                    }&noOfChildren=${
                                      this.state.children[
                                        this.state.form.insuranceType
                                      ]
                                    }`
                                  : `/`
                              }
                            >
                              <Button
                                block
                                color="primary"
                                value="buy now"
                                aria-label="buy now"
                                onClick={this.handleBuyNow}
                                className={`${classes.button} formFillBuyNowGA`}
                              >
                               <span className={`formFillBuyNowGA`}> Buy Now</span>
                              </Button>
                            </Link>
                          </GridItem>
                        </GridContainer>
                      </form>
                    </CardBody>)}
                </Card>
              }
            </GridItem>
          </GridContainer>}    
        </>
      </div>
    )

    let bgImage =
              browserWindow.navigator.userAgent.indexOf(`Edge`) !== -1
                ? categoryData.bannerImage4 && `url(${categoryData.bannerImage3.publicURL})`
                : this.state[`banner`]
                  ? categoryData.bannerImage3 && `url(${categoryData.bannerImage3.publicURL})`
                  : categoryData.bannerImage4 && `url(${categoryData.bannerImage4.publicURL})`;

    return (
      <Layout
        image2={
        categoryData &&
        [`travel`,`accident`,`special`].includes(categoryData.category) &&
        categoryData.bannerImage2 &&
        categoryData.bannerImage2.childImageSharp.fluid
        }
        small={true}
        title={categoryData.name}
        summary={categoryData.summary}
      >
        <Helmet key="helmetTags">
          <title>{categoryData.titleTag}</title>
        </Helmet>
        {process.env.ENABLE_HELMET == `true` && (
          <Helmet key="helmetTags">
            <link rel="canonical" href={urlold + 'health-insurance/'} />
            {categoryData.metaTitle != null && (
              <meta name={`title`} content={`${categoryData.metaTitle}`} />
            )}
            {categoryData.metaDescription != null && (
              <meta
                name={`description`}
                content={`${categoryData.metaDescription}`}
              />
            )}
            {categoryData.metaKeywords != null && (
              <meta name={`keywords`} content={`${categoryData.metaKeywords}`} />
            )}
            <meta property="og:title" content={categoryData.metaTitle} />
            <meta property="og:description" content={categoryData.metaDescription} />
            <meta property="og:url" content={url + `${pageContext.metaPath}`}/>
            <meta name={categoryData.metaTitle} />
            <meta property="twitter:title" content={categoryData.metaTitle} />
            <meta name="twitter:description" content={categoryData.metaDescription} />
            <meta property="twitter:url" content={url + `${pageContext.metaPath}`} />
          </Helmet>
        )}
        
        {recommendedPlans && recommendedPlans.length > 0 && categoryData.category==`health` &&  <MediaQuery maxWidth={767}>
                  {matches => {
                    return (
                      <>

                       {matches && !sevaApp() && (
                          <span>
                            <Img style={{ marginTop: `5%` }} fluid={categoryData.bannerImage2.childImageSharp.fluid} />
                          </span>
                        )}
                        {!sevaApp() &&
                        <Hidden implementation="css" mdDown>
                        <div
                          className={classes.pageHeader}
                          style={{
                            backgroundImage: bgImage,
                            backgroundBlendMode: `saturation`,
                            marginTop: `2%`,
                          }}
                          onMouseEnter={
                            matches
                              ? null
                              : () => this.handleMouseOver(`banner`)
                          }
                          onMouseLeave={
                            matches
                              ? null
                              : () => this.handleMouseLeave(`banner`)
                          }
                        >
                          <div>
                            {bannerContent(null, matches, classes)}
                          </div>
                        </div>
                        </Hidden>
                        }
                        <Hidden implementation="css" lgUp>
                        <div
                          className={classes.pageHeader}
                          style={{
                            backgroundBlendMode: `saturation`,
                            backgroundColor: `white`,
                            marginTop: `2%`
                          }}
                          onMouseEnter={
                            matches
                              ? null
                              : () => this.handleMouseOver(`banner-` + 0)
                          }
                          onMouseLeave={
                            matches
                              ? null
                              : () => this.handleMouseLeave(`banner-` + 0)
                          }
                        >
                          <div>
                            {bannerContent(null, matches, classes)}
                          </div>
                        </div>
                        </Hidden>
                      </>
                    );
                  }}
                </MediaQuery>}

        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.section}>
            <div className={classes.container}>
            {!sevaApp() &&<Hidden mdUp>
                <h1 className={classes.pageTitle}>{categoryData.name}</h1>
                <p className={classes.summary} style={{fontSize:'17px'}}>{categoryData.summary}</p>
              </Hidden>}
              
              {recommendedPlans && recommendedPlans.length > 0 && (
              <>
                <GridContainer>
                  <GridItem md={12} sm={12}>
                    <h2 className={classes.description}>Recommended Plans</h2>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  {recommendedPlans.map(document => {
                    let image = document.node.thumbnailImages && (
                      <img
                        src={document.node.thumbnailImages.publicURL}
                        width="60"
                        height="60"
                        style={{ boxShadow: `none` }}
                      />
                    );
                    let action = null;
                    if (document.node.redirectUrl) {
                      action = (
                        <a
                          rel="noopener noreferrer"
                          target={`_blank`}
                          href={sevaApp()? document.node.redirectUrl+'?sevaapp=true' : document.node.redirectUrl}
                          style={{ textDecoration: `none` }}
                        >
                          <Button className={`policyDetailPageBuyNowGA`} round style={{ background: colorCode }}>
                          <span className={`policyDetailPageBuyNowGA`}>Buy Now</span>
                          </Button>
                        </a>
                      );
                    } else {
                      action = (
                        <Link
                          to={sevaApp()? `/${document.node.urlPath}?sevaapp=true`: `/${document.node.urlPath}`}
                          style={{ textDecoration: `none` }}
                        >
                          <Button className={`policyDetailPageBuyNowGA`} round style={{ background: colorCode }}>
                          <span className={`policyDetailPageBuyNowGA`}>Buy Now</span>
                          </Button>
                        </Link>
                      );
                    }
                    return (
                      <GridItem key={document.node.id} md={4} lg={4} sm={12}>
                        <VerticalCard
                          displayComponent={
                              this.displayEmiRibbon(document.node.productId) && (
                                <img
                                  src={require(`assets/img/emi-plan-transparent.png`)}
                                  className={classes.emiLabel}
                                />
                              )
                            }
                          onHoverEffect
                          title={document.node.recommendationLabel}
                          subtitle={document.node.name}
                          image={image}
                          action={action}
                          description={document.node.summary}
                          shareable={true}
                          shareContent={
                            <>
                              <Button justIcon simple color="white">
                                <FacebookShareButton
                                  title={document.node.name}
                                  url={`${location.origin}/${document.node.urlPath}`}
                                >
                                  <ReactSVG
                                    src={withPrefix(`/web_facebook.svg`)}
                                  />
                                </FacebookShareButton>
                              </Button>

                              <Button justIcon simple color="white">
                                <TwitterShareButton
                                  title={document.node.name}
                                  url={`${location.origin}/${document.node.urlPath}`}
                                >
                                  <ReactSVG
                                    src={withPrefix(`/web_twitter.svg`)}
                                  />
                                </TwitterShareButton>
                              </Button>
                              <Button justIcon simple color="white">
                                <WhatsappShareButton
                                  title={document.node.name}
                                  url={`${location.origin}/${document.node.urlPath}`}
                                >
                                  <ReactSVG
                                    src={withPrefix(`/web_whatsapp.svg`)}
                                  />
                                </WhatsappShareButton>
                              </Button>
                            </>
                          }
                          plain
                          alignItems="center"
                        />
                      </GridItem>
                    );
                  })}
                </GridContainer>
              </>
              )}
              {allPlansArr && allPlansArr.length > 0 && (
              <>
                <GridContainer>
                  <GridItem md={12} sm={12}>
                    <h2 className={classes.description}>{data.allStrapiInsuranceplans && data.allStrapiInsuranceplans.edges[0].node.category == `special` ?  'Special Plans': 'Other Plans'}</h2>
                  </GridItem>
                </GridContainer>
                <CustomHidden
                  items={allPlansArr}
                  initState={3}
                  ctaLabel={`Show More Products`}
                  toggleCtaLabel={`Show Less Products`}
                  round={true}
                  textAlign={`center`}
                  colorCode={colorCode}
                />
              </>
              )}
            </div>
          </div>
        </div>
        {data.allStrapiInsuranceplans.edges[0].node.category == `health` && <HealthInsurancePageSEO />}
        {data.allStrapiInsuranceplans.edges[0].node.category == `travel` && <TravelInsurancePageSEO/>}
        <div
          className={`${classes.testimonials} ${classes.sectionDark} ${classes.testimonial2}`}
        >
         {!sevaApp() &&<div className={classes.section}>
            <h2
              className={`${classes.description} ${classes.sectionTitle}`}
              style={{ textAlign: `center`, paddingTop: `20px` }}
            >
            Other Products
            </h2>
            <div className={classes.container}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Carousel {...settings}>
                    {nonCategories.map((document, index) => {
                      let image = null;
                      // console.log(document.node.image,document.node.name)
                      if (
                        document.node.image &&
                      document.node.image.childImageSharp
                      ) {
                        image = (
                          <span>
                            <Link to={`/${document.node.url}`}>
                              <Img
                                fluid={document.node.image.childImageSharp.fluid}
                              />
                            </Link>
                            <div
                              style={{
                                backgroundImage: `url(${document.node.image.childImageSharp.fluid})`,
                                opacity: `1`
                              }}
                            />
                          </span>
                        );
                      }
                      else if(document.node.category == 'individual'){
                        image = (
                          <span>
                            <Link to={`/${document.node.url}`}>
                              <img src='Individual-health-insurance.png' />
                            </Link>
                          </span>
                        );
                      }
                      return (
                        <div key={`other-` + index}>
                          <GridItem
                            xs={12}
                            sm={12}
                            md={12}
                            style={{ marginBottom: `50px` }}
                          >
                            <VerticalCard
                              title={document.node.name}
                              image={image}
                              action={
                                <Link
                                  to={`${document.node.url}`}
                                  style={{ textDecoration: `none` }}
                                >
                                  <Button
                                  className={`policyDetailPageBuyNowGA`}
                                    round
                                    style={{
                                      background: document.node.colorCode
                                    }}
                                  >
                                    <span className={`policyDetailPageBuyNowGA`}>{document.node.ctaLabel}</span>
                                  </Button>
                                </Link>
                              }
                              description={document.node.summary}
                              alignItems="left"
                              noShadow
                            />
                          </GridItem>
                        </div>
                      );
                    })}
                  </Carousel>
                </GridItem>
              </GridContainer>
            </div>
          </div>}
        </div>
        <PortabilityForm handleClose={(modal) => this.handleClose(modal)} signupModal={this.state.signupModal} />
      </Layout>
    );
  }
}
ProductList.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  location: PropTypes.object,
  pageContext: PropTypes.object
};

export default withStyles(productListStyle)(ProductList);

export const productListQuery = graphql`
  query ProductList($category: String!) {
    allStrapiInsuranceplans(
      filter: { category: { eq: $category }, isVisible: { eq: true } }
    ) {
      edges {
        node {
          id
          productId
          name
          urlPath
          summary
          category
          isRecommended
          recommendationLabel
          isWithdrawn
          redirectUrl
          sequence
          isVisiblelist
          thumbnailImages {
            publicURL
          }
        }
      }
    }
    allStrapiInsuranceplancategories(filter: { category: { in: ["parents","individual","group"] } }) {
      edges {
        node {
          name
          summary
          category
          url
          colorCode
          ctaLabel
          image {
            publicURL
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    strapiInsuranceplancategories(category: { eq: $category }) {
      name
      summary
      category
      url
      colorCode
      titleTag
      metaTitle
      metaKeywords
      metaDescription
      bannerImage2 {
        publicURL
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bannerImage3 {
        publicURL
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bannerImage4 {
        publicURL
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;