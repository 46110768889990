import React from "react";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import WellnessBenefitsStyle from "ps-assets/jss/WellnessBenefitsStyle.jsx";
import { sevaApp } from "util/LayoutUtil";

const TravelInsurancePageSEO = ({ classes }) => {
  return (
    <>
      {!sevaApp() && (
        <GridContainer className={classes.containerDisplay}>
          <GridItem
            md={11}
            sm={11}
            xs={11}
            className={classNames(
              classes.mrAuto,
              classes.mlAuto,
              classes.infoArea,
              classes.handleMarginRight
            )}
          >
            {/* <GridItem style={{ paddingLeft: "0px" }}>
              <h2 className={classes.h2hTitle}>Travel Insurance Plans</h2>
            </GridItem> */}
            <br/>
            <h5>
              Travel gives you great stories, most cherished memories and
              countless learnings which can be shared with your close ones.
              <br />
              <br />
              Embarking on international travel, whether it is your business,
              for education or a leisure trip, requires extensive preparation to
              ensure a successful and safe journey. Even the best-planned trips
              can be jeopardised by unexpected events. Imagine scenarios like
              losing a passport or checked-in baggage, which has your
              travellers&apos; cheques, or you falling ill and required to be
              hospitalised in a foreign country. Any of these situations may
              interrupt your joyous trip or spoil your business travel. Hence,
              choosing the best Travel Insurance plan will ensure that such
              incidents don&apos;t spoil your perfectly planned vacation.
              <br />
              <br />
              It is always better to be safe than sorry! - Make a wise decision to buy
              a Travel Insurance policy with the best coverage options that
              secures you and your family from travel-related emergencies. You
              can also buy the best Travel Insurance plan online by comparing
              the benefits and premiums of various travel insurance plans and
              choose the right plan that suits your needs.
            </h5>

            <GridItem style={{ paddingLeft: "0px" }}>
              <h2 className={classes.h2hTitle}>
                Why do you need Travel Insurance?
              </h2>
            </GridItem>
            <h5>
              Once you have planned to travel to foreign countries, it is
              important to ensure your travel is safe and enjoyable.
              <br />
              <br />
              Travel Insurance is meant to cover your medical and financial
              emergencies, which might arise during your travel abroad. Here are
              some reasons why Travel Insurance is needed. 
              <br />
              <br />
              Let&apos;s read up!
            </h5>
            <GridItem style={{ paddingLeft: "0px" }}>
              <h2 className={classes.h2hTitle}>
                Top reasons why you need a Travel Insurance
              </h2>
            </GridItem>
            <GridItem style={{ paddingLeft: "0px" }}>
              <h3 className={classes.h3hTitle}>
                You may encounter sickness or Injury
              </h3>
            </GridItem>
            <h5>
              There are many reasons due to which you may fall sick. Late-night
              flights, air conditioning, change in temperature, time zone
              changes and varied cuisines to name a few. The current pandemic
              situation demands world countries make it mandatory for the
              travellers to take vaccination for specific diseases beforehand.
              This might also make you feel ill on your travel time and require
              hospitalisation. A Travel Insurance plan can cover the expenses
              incurred due to medical emergencies or accidents during your trip.
              Such unexpected eventualities are covered up to a specified limit
              mentioned in the policy. A Travel Insurance plan coverages include
              expenses of in-patient and outpatient hospitalisation, daily cash
              allowance, transportation to the nearest hospitals and many more.
            </h5>
            <GridItem style={{ paddingLeft: "0px" }}>
              <h3 className={classes.h3hTitle}>
                Delay or loss of checked-in luggage
              </h3>
            </GridItem>
            <h5>
              A common scenario while travelling overseas or even within the
              country is misplaced luggage or delayed baggage. Travel Insurances
              usually provide coverage for your delayed or misplaced baggage.
              They will help you recover your misplaced bags and will also
              reimburse any amount spent on essential items packed in the
              baggage as per the terms and conditions of the policy. Thereby
              ensuring hassle-free travel.
            </h5>
            <GridItem style={{ paddingLeft: "0px" }}>
              <h3 className={classes.h3hTitle}>
                Flight cancellations and delays
              </h3>
            </GridItem>
            <h5>
              When travelling to different countries, you would definitely have
              a pre-planned itinerary. Delayed flights or rescheduling of flight
              timings due to bad weather or missed connecting flights can
              disturb the whole trip. This may be due to a cancelled flight or
              any other aspect. Your Travel Insurance comes in handy here; it
              can reimburse the amount spent on the purchase of meals and
              refreshments, etc., for the period of the delay in submission of
              bills.
            </h5>
            <GridItem style={{ paddingLeft: "0px" }}>
              <h3 className={classes.h3hTitle}>Loss of passport</h3>
            </GridItem>
            <h5>
              If an insured loses the passport before your trip or even while
              you&apos;re away, the insured can reimburse the actual expenses
              incurred in obtaining a duplicate or fresh passport. In that way,
              the insurance plan can aid in avoiding financial and mental
              stress.
            </h5>
            <GridItem style={{ paddingLeft: "0px" }}>
              <h3 className={classes.h3hTitle}>
                Cancelling or changing your trip before you start
              </h3>
            </GridItem>
            <h5>
              You may intend to cancel your trip when you or your family members
              fall ill or due to natural calamities. In such cases, cancelling
              pre-paid air tickets is often expensive. Getting covered under a
              Travel Insurance plan may make you worry-free financially, as you
              are covered in such situations.
            </h5>
            <GridItem style={{ paddingLeft: "0px" }}>
              <h3 className={classes.h3hTitle}>Emergency evacuation</h3>
            </GridItem>
            <h5>
              Emergency evacuation is a must during cases of natural calamities
              like Tsunamis, earthquakes, volcanic ash clouds, political
              uprisings and terrorist attacks, etc. If you have an insurance
              cover, the insurance company takes responsibility for evacuating
              you back to the respective homeland and providing medical help
              when needed.
            </h5>
            <GridItem style={{ paddingLeft: "0px" }}>
              <h3 className={classes.h3hTitle}>24/7 support</h3>
            </GridItem>
            <h5>
              One of the benefits of Travel Insurance is access to a 24x7
              emergency helpline when you are in circumstances of emergency in
              an unknown location in a foreign land. You can get guidance and
              directions even in such an adverse situation. Hence, it is
              essential to carry a copy of the insurance document while
              travelling. The helplines can fetch your details at the nearest
              hospital or can help you in arranging for evacuation or emergency
              services. 
              <br />
              <br />
              For all these reasons, Travel Insurance is essential and
              undoubtedly a wise investment to make before you travel.
            </h5>
            <GridItem style={{ paddingLeft: "0px" }}>
              <h3 className={classes.h3hTitle}>
                Features of Travel Insurance policy
              </h3>
            </GridItem>
            <h5>
              Investing in a travel plan is always advantageous and protects the
              interests of those travelling abroad. The risks associated with
              human life are increasing exponentially due to crime, terrorist
              attacks, thefts and robbery. Hence it is always important to be
              well-secured with a Travel Insurance that offers a worldwide cover
              for travel exigencies.
              <h5>
                Travel medical covers for in/outpatient hospitalisation along
                with daily allowance.
              </h5>
              <h5>
                The top features of health insurance include the protection
                against Travel inconveniences such as,
              </h5>
              <ul>
                <li>Coverage for loss or delay of checked-in Baggage</li>
                <li>Coverage for loss of Passport and related documents</li>
                <li>Coverage for flight delay</li>
                <li>Coverage for missed flight/connecting flight</li>
                <li>Coverage for hijack distress</li>
                <li>Coverage for trip cancellation and delay</li>
                <li>
                  Personal accident coverage during travel, including dental
                  emergency expenses following an accident.
                </li>
                <li>Coverage for frequent travellers</li>
                <li>
                  Coverage for legal liability for bodily injury or property
                  damage to a third party.
                </li>
                <li>
                  Coverage for costs relating to travel and accommodation
                  incurred due to missed departure/connection are covered.
                </li>
                <li>Coverage for persons going abroad for studies.</li>
                <h5>And many more.</h5>
              </ul>
              These facts together portrays the importance of Travel Insurance.
              And depending on what plan you buy, the coverages vary. The
              coverages and exclusions vary from policy to policy. Hence, it is
              always good to read your policy before you buy.
            </h5>
          </GridItem>
        </GridContainer>
      )}
    </>
  );
};

TravelInsurancePageSEO.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  pageContext: PropTypes.object,
};
export default withStyles(WellnessBenefitsStyle)(TravelInsurancePageSEO);
